import settingsService from "@/requests/admin/settingsService"

export default {
    state: {
        settingList: []
    },
    mutations: {
        setSettingList(state, list) {
            state.settingList = list[0].data
        },
        clearSettingList(state) {
            state.settingList = []
        },
    },
    actions: {
        async getSettingList({ commit, getters }) {
            try {
                await settingsService.getSettingList().then((res) => {
                    if (res.status == 'Success') {
                        const newList = { ...getters.settingList, ...[res] }
                        commit('setSettingList', newList)
                    }
                })
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
       settingList: s => s.settingList,
    }
}
