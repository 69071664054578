<template>
  <v-col class="px-0 py-0" style="text-align: center; margin-bottom: 24px">
    <div class="tripCard">
      <v-row
        no-gutters
        align="center"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 20px'"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <!-- ROUTE NAME -->
        <p
          class="routeName"
          style="margin-bottom: 0px"
          :style="!isActive ? 'color: #B5B5B5;' : ''"
        >
          {{ ticket?.departure_info?.name }} -
          {{ ticket?.arrival_info?.name }}
        </p>
        <v-row
          no-gutters
          justify="end"
          v-if="!$vuetify.breakpoint.smAndDown && isActive"
        >
          <div
            v-if="ticket.status == 'Booking'"
            style="
              border-radius: 10px;
              background: rgba(251, 187, 85, 0.1);
              padding: 8px 16px;
              width: max-content;
              height: 40px;
              color: #d98500;
              font-family: 'MacPaw Fixel Display';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-right: 12px;
            "
          >
            <v-row no-gutters align="center">
              <img
                src="@/assets/img/iconsSvg/bookingTicketIcon.svg"
                alt="booking"
                style="margin-right: 8px"
              />
              <span>Заброньовано</span>
            </v-row>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                src="@/assets/img/iconsSvg/qrCodeIcon.svg"
                alt="qr code"
                v-if="isActive"
                @click="showQrCodeModal = true"
                style="cursor: pointer"
              />
            </template>
            <span>Електронний квиток</span>
          </v-tooltip>
          <button
            v-if="
              $vuetify.breakpoint.smAndDown &&
              isActive &&
              ticket.status !== 'Booking'
            "
            class="downloadBtn"
            :style="
              new Date() > new Date(ticket.info.departed_at)
                ? 'margin-left: 32px;'
                : ''
            "
            @click="downloadTicket"
          >
            <div class="downloadIcon" style="background-color: #149e51" />
          </button>
        </v-row>
      </v-row>
      <v-row
        v-else
        no-gutters
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 20px'"
      >
        <v-col class="py-0" style="text-align: left">
          <!-- ROUTE NAME -->
          <p
            class="routeName"
            style="margin-bottom: 0px"
            :style="!isActive ? 'color: #B5B5B5;' : ''"
          >
            {{ ticket?.departure_info?.name }} -
            {{ ticket?.arrival_info?.name }}
          </p>
        </v-col>
        <v-col class="px-0">
          <v-row no-gutters justify="end" v-if="isActive">
            <v-col class="px-0 py-0">
              <v-row no-gutters justify="end" v-if="ticket.status == 'Booking'">
                <div
                  style="
                    border-radius: 10px;
                    background: rgba(251, 187, 85, 0.1);
                    padding: 4px 8px;
                    width: max-content;
                    height: 25px;
                    color: #d98500;
                    font-family: 'MacPaw Fixel Display';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 6px;
                  "
                >
                  <v-row no-gutters align="center">
                    <img
                      src="@/assets/img/iconsSvg/bookingTicketIcon.svg"
                      alt="booking"
                      width="16px"
                      height="16px"
                      style="margin-right: 8px"
                    />
                    <span>Бронювання</span>
                  </v-row>
                </div>
              </v-row>

              <v-row no-gutters align="center" justify="end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-bind="attrs"
                      v-on="on"
                      src="@/assets/img/iconsSvg/qrCodeIcon.svg"
                      alt="qr code"
                      v-if="isActive"
                      @click="showQrCodeModal = true"
                      style="cursor: pointer"
                    />
                  </template>
                  <span>Електронний квиток</span>
                </v-tooltip>
                <button
                  v-if="
                    $vuetify.breakpoint.smAndDown &&
                    isActive &&
                    ticket.status !== 'Booking'
                  "
                  class="downloadBtn"
                  :style="
                    new Date() > new Date(ticket.info.departed_at)
                      ? 'margin-left: 32px;'
                      : 'margin-left: 12px'
                  "
                  @click="downloadTicket"
                >
                  <div class="downloadIcon" style="background-color: #149e51" />
                </button>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="start">
        <!-- PASSANGER INFO -->
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'padding-top: 20px!important; text-align: left; display:flex; align-items:center'
              : 'text-align: left; border-right: 1.5px solid #efefef'
          "
          class="px-0 py-0"
        >
          <v-row no-gutters align="center">
            <div class="cardLine" />
            <v-col
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'padding: 0;'
                  : 'padding: 30px 0px'
              "
            >
              <!-- PASSANGER FIRST,LAST NAME -->
              <!-- <v-row
              no-gutters
              align="center"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-col class="px-0 py-0" cols="5"
                ><span class="sectionName">Прізвище та ім’я:</span></v-col
              >
              <v-col class="px-0 py-0" cols="7"
                ><span class="sectionText">Герцюк Ігор</span></v-col
              >
            </v-row> -->
              <!-- PASSANGER PHONE NUMBER -->
              <!-- <v-row
              no-gutters
              align="center"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-col class="px-0 py-0" cols="5"
                ><span class="sectionName">Номер телефону:</span></v-col
              >
              <v-col class="px-0 py-0" cols="7"
                ><span class="sectionText">+380875648890</span></v-col
              >
            </v-row> -->
              <!-- QUANTITY PASSANGERS -->
              <v-row
                no-gutters
                align="center"
                v-if="!$vuetify.breakpoint.smAndDown"
              >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionName">Кількість місць:</span></v-col
                >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionText"
                    >{{ ticket.seats_count }}
                    {{
                      ticket.seats_count == 1
                        ? "місце"
                        : ticket.seats_count > 1 && ticket.seats_count < 5
                        ? "місць"
                        : "місця"
                    }}</span
                  ></v-col
                >
              </v-row>
              <v-row
                no-gutters
                align="center"
                v-if="!$vuetify.breakpoint.smAndDown"
                style="margin-top: 12px"
              >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionName">Місце:</span></v-col
                >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionText">Вільне</span></v-col
                >
              </v-row>
              <v-row
                no-gutters
                align="center"
                v-if="!$vuetify.breakpoint.smAndDown"
                style="margin-top: 12px"
              >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionName">Платформа:</span></v-col
                >
                <v-col class="px-0 py-0" cols="6"
                  ><span class="sectionText">
                    {{
                      ticket?.info?.route_info?.route_city?.find(
                        (route) => route.city_id == ticket.departure_city
                      ).platform
                    }}
                  </span></v-col
                >
              </v-row>
            </v-col>
          </v-row>

          <!-- <img
            src="@/assets/img/iconsSvg/qrCodeIcon.svg"
            alt="qr code"
            v-if="isActive && $vuetify.breakpoint.smAndDown"
            @click="showQrCodeModal = true"
          /> -->
        </v-col>
        <!-- TICKET ROUTE INFO -->
        <v-col cols="12" xl="8" lg="8" md="8" sm="12" class="py-0">
          <!-- IN ACTIVE TICKET LABEL -->
          <v-row no-gutters justify="center" v-if="!isActive">
            <span class="ticketExpired">Термін дії квитка закінчився</span>
          </v-row>
          <!-- ROUTE INFO -->
          <v-row no-gutters align="center">
            <!-- DEPARTURE CITY AND TIME -->
            <v-col
              cols="4"
              xl="3"
              lg="4"
              md="3"
              sm="4"
              style="padding: 50px 15px !important; text-align: left"
              :style="
                !isActive
                  ? 'padding-top: 5px;'
                  : $vuetify.breakpoint.smAndDown
                  ? 'padding: 13px 0px 13px 0px!important'
                  : 'padding-top: 50px!important'
              "
            >
              <span
                class="tripCardDate"
                :style="!isActive ? 'color: #B5B5B5;' : ''"
              >
                {{
                  new Date(
                    addMinutesToDate(
                      ticket?.info?.departed_at,
                      ticket?.info?.route_info?.route_city.filter(
                        (city) => city.city_id == ticket.departure_city
                      )?.[0].time_from_start
                    )
                  ).toLocaleDateString("uk-UA", {
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                  })
                }}
                <span :style="!isActive ? 'color: #B5B5B5;' : 'color: #149e51'">
                  {{
                    ticket?.info?.route_info?.route_city.filter(
                      (city) => city.city_id == ticket.departure_city
                    )?.[0].arrival
                  }}</span
                >
              </span>
              <div style="text-align: left">
                <span
                  class="tripCardCity"
                  :style="!isActive ? 'color: #B5B5B5;' : ''"
                  >{{ ticket?.departure_info?.name }},<span
                    style="font-weight: 400"
                  >
                    {{
                      ticket?.info?.route_info?.route_city.filter(
                        (city) => city.city_id == ticket.departure_city
                      )?.[0].address
                    }}
                  </span></span
                >
              </div>
            </v-col>
            <!-- HOURS ON THE ROAD -->
            <v-col
              cols="4"
              xl="6"
              lg="4"
              md="6"
              sm="4"
              style="padding: 30px 20px 30px 0px !important; text-align: center"
              :style="
                !isActive
                  ? 'padding-top: 5px;'
                  : $vuetify.breakpoint.smAndDown
                  ? 'padding: 30px 10px 30px 10px !important;'
                  : 'padding-top: 30px!important'
              "
            >
              <span class="differentHours">{{
                setDifferentTime(deparuteDate, arrivalDate)
              }}</span>
              <v-row no-gutters align="center">
                <div class="dot"></div>
                <div class="line"></div>
                <div class="dot"></div>
              </v-row>
            </v-col>
            <!-- ARRIVAL CITY AND TIME -->
            <v-col
              cols="4"
              xl="3"
              lg="3"
              md="3"
              sm="4"
              style="padding: 50px 0px !important; text-align: left"
              :style="
                !isActive
                  ? 'padding-top: 5px'
                  : $vuetify.breakpoint.smAndDown
                  ? 'padding: 13px 0px 13px 0px!important'
                  : 'padding-top: 50px!important'
              "
            >
              <span
                class="tripCardDate"
                :style="!isActive ? 'color: #B5B5B5;' : ''"
              >
              {{
                new Date(
                  addMinutesToDate(
                    ticket?.info?.departed_at,
                    ticket?.info?.route_info?.route_city.filter(
                      (city) => city.city_id == ticket.arrival_city
                    )?.[0].time_from_start
                  )
                ).toLocaleDateString("uk-UA", {
                  month: "short",
                  day: "numeric",
                  weekday: "short",
                })
              }}
                <span :style="!isActive ? 'color: #B5B5B5;' : 'color: #149e51'">
                  {{
                    ticket?.info?.route_info?.route_city.filter(
                      (city) => city.city_id == ticket.arrival_city
                    )?.[0].arrival
                  }}</span
                >
              </span>
              <div
                style="text-align: left"
                :style="!isActive ? 'color: #B5B5B5;' : ''"
              >
                <span
                  class="tripCardCity"
                  :style="!isActive ? 'color: #B5B5B5;' : ''"
                  >{{ ticket?.arrival_info?.name }},<span
                    style="font-weight: 400"
                  >
                    {{
                      ticket?.info?.route_info?.route_city.filter(
                        (city) => city.city_id == ticket.arrival_city
                      )?.[0].address
                    }}
                  </span></span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-col
        class="px-0 py-0"
        v-if="
          $vuetify.breakpoint.smAndDown &&
          ticket.status == 'Booking' &&
          isActive
        "
      >
        <button
          style="
            width: 100% !important;
            height: 40px !important;
            justify-content: center;
          "
          class="downloadBtn"
          :style="
            new Date() > new Date(ticket.info.departed_at)
              ? 'margin-left: 32px;'
              : ''
          "
          @click="payTicket"
        >
          <div v-html="payBtn"></div>
        </button>
        <button
          class="detailBtn"
          :style="
            new Date() > new Date(ticket.info.departed_at)
              ? 'margin-left: 0px;'
              : ''
          "
          style="
            width: 100%;
            margin-top: 20px;
            color: #bc0b0b;
            background: transparent;
          "
          @click="showConfirmModal = true"
        >
          <v-row no-gutters justify="center"> Скасувати бронь </v-row>
        </button>
        <button
          class="detailBtn"
          :style="
            new Date() > new Date(ticket.info.departed_at)
              ? 'margin-left: 0px;'
              : ''
          "
          style="width: 100%; margin-top: 20px"
          @click="showDetailTripInfo = !showDetailTripInfo"
        >
          <v-row no-gutters justify="center">
            Детальніше<v-icon color="#149E51" small style="margin-left: 5px">{{
              showDetailTripInfo ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-row>
        </button>
      </v-col>
      <v-row
        no-gutters
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 20px'"
      >
        <!-- DETAIL BTN DESKTOP-->
        <button
          v-if="!$vuetify.breakpoint.smAndDown"
          class="detailBtn"
          :style="
            new Date() > new Date(ticket.info.departed_at)
              ? 'margin-left: 0px;'
              : ''
          "
          @click="showDetailTripInfo = !showDetailTripInfo"
        >
          Детальніше<v-icon color="#149E51" small style="margin-left: 5px">{{
            showDetailTripInfo ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </button>
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.smAndDown ? 'space-between' : 'end'"
          align="center"
        >
          <!-- SHOW IF TICKET EXPIRED -->
          <span
            class="searchNearbyTicket"
            v-if="!isActive"
            @click="
              $route.path == '/' ? $emit('close') : $emit('close'),
                $router.push('/')
            "
            >Пошук найближчих квитків</span
          >
          <!-- SHOW IF TICKET NOT EXPIRED -->
          <!-- QR CODE BTN -->
          <!-- <img
          src="@/assets/img/iconsSvg/qrCodeIcon.svg"
          alt="qr code"
          v-if="
            isActive &&
            !$vuetify.breakpoint.smAndDown &&
            ticket.status == 'Payed'
          "
          @click="showQrCodeModal = true"
        />  <span
          class="qrCodeBtn"
          v-if="
            isActive &&
            !$vuetify.breakpoint.smAndDown &&
            ticket.status == 'Payed'
          "
          @click="showQrCodeModal = true"
          >E-tickets</span
        >
        <div v-html="payBtn" v-if="payBtn" /> -->
          <!-- CONTINUE ORDER BTN -->
          <v-btn
            v-if="
              ticket.status == 'Prebooking' && ticket.ticket_info.length == 0
            "
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.1em;
              color: #243949;
            "
            @click="continueBooking"
            text
            ><span>Продовжити бронювання</span>
          </v-btn>
          <!-- CANCEL ROUTE BTN -->
          <span
            class="cancelRouteBtn"
            v-if="
              isActive &&
              ticket.status !== 'Canceled' &&
              new Date() < new Date(ticket.info.departed_at)
            "
            @click="showConfirmModal = true"
            >Відмінити</span
          >
          <!-- DETAIL BTN MOBILE-->
          <v-row
            no-gutters
            justify="end"
            v-if="$vuetify.breakpoint.smAndDown && ticket.status !== 'Booking'"
          >
            <button
              class="detailBtn"
              :style="
                new Date() > new Date(ticket.info.departed_at)
                  ? 'margin-left: 32px;'
                  : ''
              "
              @click="showDetailTripInfo = !showDetailTripInfo"
            >
              Детальніше<v-icon
                color="#149E51"
                small
                style="margin-left: 5px"
                >{{
                  showDetailTripInfo ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon
              >
            </button>
          </v-row>
          <!-- DOWNLOAD BTN -->
          <button
            v-if="
              !$vuetify.breakpoint.smAndDown &&
              isActive &&
              ticket.status == 'Payed'
            "
            class="downloadBtn"
            :style="
              new Date() > new Date(ticket.info.departed_at)
                ? 'margin-left: 32px;'
                : ''
            "
            @click="downloadTicket"
          >
            Завантажити
            <div class="downloadIcon" style="margin-left: 10px" />
          </button>
          <button
            v-if="
              !$vuetify.breakpoint.smAndDown &&
              isActive &&
              ticket.status == 'Booking'
            "
            style="
              width: 148px;
              height: 40px;
              justify-content: center;
              background: none;
            "
            :style="
              new Date() > new Date(ticket.info.departed_at)
                ? 'margin-left: 32px;'
                : ''
            "
            @click="payTicket"
          >
            <div v-html="payBtn"></div>
          </button>
        </v-row>
      </v-row>
    </div>
    <!-- ROUTE DETAIL INFO -->
    <v-expand-transition>
      <div class="detailTripCard" v-if="showDetailTripInfo">
        <!-- INFO OF THE BUS -->
        <p class="routeDetailInfoSectionName">Автобус</p>
        <v-row
          no-gutters
          :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
        >
          <img
            src="@/assets/img/iconsSvg/busIcon.svg"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 6px;' : ''"
            alt="bus icon"
          />
          <span class="routeSectionText" v-if="!$vuetify.breakpoint.smAndDown">
            <span
              style="
                text-decoration-line: underline;
                color: #149e51;
                margin-left: 10px;
              "
            >
              {{ ticket?.info?.bus_info.model }}
            </span>
            <span style="margin-left: 10px"
              >Номер автобуса:
              {{ ticket?.info?.bus_info.government_number }}
            </span>
          </span>
          <v-col v-else class="px-0 py-0" style="margin-left: 10px">
            <v-col cols="12" class="px-0 py-0">
              <span class="routeSectionText">
                <span style="text-decoration-line: underline; color: #149e51">
                  {{ ticket?.info?.bus_info.model }}
                </span>
              </span>
            </v-col>
            <v-col cols="12" class="px-0 py-0">
              <span class="routeSectionText">
                <span
                  >Номер автобуса:
                  {{ ticket?.info?.bus_info.government_number }}
                </span>
              </span>
            </v-col>
          </v-col>
        </v-row>
        <!-- INFO OF THE ROUTE -->
        <p class="routeDetailInfoSectionName" style="margin-top: 30px">
          МАРШРУТ
        </p>
        <v-row no-gutters align="center" style="margin-bottom: 10px">
          <!-- ROUTE NAME -->
          <span class="routeSectionText">
            {{ ticket?.info?.route_info?.departure_info?.name }} -
            {{ ticket?.info?.route_info?.destination_info?.name }}
            <span
              class="detailRouteBtn"
              @click="
                $router.push('/routes/' + ticket?.info?.route_info?.slug),
                  $emit('close')
              "
              >Детальний маршрут</span
            >
          </span></v-row
        >
        <v-row no-gutters align="center" style="margin-bottom: 10px">
          <!-- TIME ON THE ROAD-->
          <span class="routeSectionText"
            >В дорозі {{ setDifferentTime(deparuteDate, arrivalDate) }}</span
          >
          <v-tooltip bottom max-width="200px" color="rgba(27, 27, 27, 0.8);">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="helpIcon" />
            </template>
            <span
              >Час вказаний приблизний і залежить від часу проходження кордону
              та інших обставин</span
            >
          </v-tooltip>
        </v-row>
        <!-- <v-row no-gutters align="center" style="margin-bottom: 10px">
          FREE SEATS 
          <span class="routeSectionText"
            >Вільних місць на цьому рейсі залишилося:
            <span style="color: #149e51">33</span>
          </span>
        </v-row> -->
        <p
          class="routeDetailInfoSectionName"
          style="margin-top: 30px"
          v-if="ticket.ticket_info.length"
        >
          Дані пасажирів
        </p>
        <!-- EACH PASSANGER DATA -->
        <v-row no-gutters>
          <v-col
            style="text-align: left; margin-bottom: 15px"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            v-for="(passanger, index) in ticket.ticket_info"
            :key="passanger.uuid"
          >
            <p class="sectionName" style="color: #149e51">
              Пасажир {{ index + 1 }}
            </p>
            <!-- PASSANGER FIRST,LAST NAME -->
            <v-row no-gutters align="center">
              <v-col class="px-0 py-0" cols="5"
                ><span class="sectionName">Прізвище та ім’я:</span></v-col
              >
              <v-col class="px-0 py-0" cols="7"
                ><span class="sectionText"
                  >{{ passanger.first_name }} {{ passanger.last_name }}</span
                ></v-col
              >
            </v-row>
            <!-- PASSANGER PHONE NUMBER -->
            <v-row no-gutters align="center">
              <v-col class="px-0 py-0" cols="5"
                ><span class="sectionName">Номер телефону:</span></v-col
              >
              <v-col class="px-0 py-0" cols="7"
                ><span class="sectionText">{{
                  passanger.phone_number
                }}</span></v-col
              >
            </v-row>
            <!-- PASSANGER QUANTITY SEATS -->
            <v-row no-gutters align="center">
              <v-col class="px-0 py-0" cols="5"
                ><span class="sectionName">Емайл:</span></v-col
              >
              <v-col class="px-0 py-0" cols="7"
                ><span class="sectionText">{{ passanger.email }}</span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!-- QR CODE MODAL -->
    <qr-code-modal
      v-if="showQrCodeModal"
      :visible="showQrCodeModal"
      :ticket="ticket"
      @close="showQrCodeModal = false"
      :text="qrText"
    />
    <!-- CONFIRM MODAL -->
    <confirm-modal
      v-if="showConfirmModal"
      :visible="showConfirmModal"
      modalTitle="Ви впевнені, що бажаєте відмінити поїздку?"
      modalText="Умови повернення коштів:
 до 240 год. до від'їзду: 80%
 від 240 год. до 72 год. до від'їзду: 50%
 від 72 год. до 24 год. до від'їзду: 10%
 менше 24 год. до від'їзду: квиток не повертається"
      @close="showConfirmModal = false"
      @confirm="cancelOrder"
    />
    <!-- SUCCESS MODAL -->
    <success-modal
      v-if="showSuccessModal"
      :visible="showSuccessModal"
      title="Ваш квиток анульований!"
      :text="true"
      :cancelBtn="true"
      :searchBtn="true"
      @close="(showSuccessModal = false), $emit('close')"
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="`Квиток ${ticket?.info?.route_info?.departure_info.name} - ${
        ticket?.info?.route_info?.destination_info.name
      } ${new Date(ticket?.info?.departed_at.substr(0, 10)).toLocaleDateString(
        'uk-UA',
        { month: 'short', day: 'numeric' }
      )}  ${ticket?.ticket_info[indexTicket]?.first_name} 
    ${ticket?.ticket_info[indexTicket]?.last_name}`"
      :pdf-quality="2"
      :manual-pagination="true"
      :paginate-elements-by-height="200"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          id="pdfTicket"
          ref="pdfTicket"
          :ticket="ticket"
          :isPdf="isPdf"
          :text="qrText"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </v-col>
</template>
  
  <script>
import confirmModal from "@/components/UI/Modals/confirmModal.vue";
import SuccessModal from "@/components/UI/Modals/successModal.vue";
import QrCodeModal from "@/components/UI/Modals/qrCodeModal.vue";
import bookingTicketService from "@/requests/main/bookingTicketService";
import diffrentTimeMixin from "@/mixins/diffrentTimeMixin";
import VueHtml2pdf from "vue-html2pdf";
import pdfTicket from "../pdfTicket.vue";
export default {
  mixins: [diffrentTimeMixin],
  components: {
    confirmModal,
    SuccessModal,
    QrCodeModal,
    VueHtml2pdf,
    pdfTicket,
  },
  data: () => ({
    showDetailTripInfo: false,
    showQrCodeModal: false,
    showConfirmModal: false,
    showSuccessModal: false,
    qrText: "",
    payBtn: "",
    isSendEmail: false,
    isDownloadTicket: false,
    showEmailSuccessIcon: false,
    showDownloadSuccessIcon: false,
    isPdf: false,
    showSendEmailLoader: false,
    indexTicket: 0,
  }),
  props: {
    isActive: {
      require: true,
    },
    ticket: {
      require: true,
    },
  },
  mounted() {
    this.setTripTime();
    this.setQrText();
    if (
      this.ticket.ticket_info.length > 0 &&
      this.ticket.status == "Booking" &&
      this.ticket.is_payed == "No"
    ) {
      this.payTicket();
    }
  },
  methods: {
    async cancelOrder() {
      await bookingTicketService.cancelOrder(this.ticket.uuid).then((res) => {
        if (res.status == "Success") {
          this.showConfirmModal = false;
          this.showSuccessModal = true;
        }
      });
    },
    setQrText() {
      this.qrText = `${location.protocol}//${location.host}/order_qr/${this.ticket.uuid}`;
    },
    continueBooking() {
      sessionStorage.setItem("order_uuid", this.ticket.uuid);
      sessionStorage.setItem("trip_id", this.ticket.schedule_id);
      let trip = {
        departureCity: this.ticket.departure_city,
        arrivalCity: this.ticket.arrival_city,
        date: this.ticket.created_at.slice(0, 10),
        qty: this.ticket.seats_count,
        trip_id: this.ticket.schedule_id,
      };
      sessionStorage.setItem("recent_trip_search", JSON.stringify(trip));
      setTimeout(() => {
        this.$router.push(`/passanger`);
        this.$emit("close");
      }, 500);
    },
    async payTicket() {
      await bookingTicketService.payTicket(this.ticket.uuid).then((res) => {
        if (res.status == "success") {
          this.payBtn = res.data.replace(
            'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
            `src="${
              this.$vuetify.breakpoint.smAndDown
                ? "https://i.ibb.co/pry7Zmx/log-in.png"
                : "https://i.ibb.co/zbN9CGs/log-in.png"
            } " style="width:${
              this.$vuetify.breakpoint.smAndDown ? "100%" : "148px"
            }; height: 40px;" ref="payBtn"`
          );
        }
        //this.$refs.payBtn.click();
        console.log(this.$refs);
      });
    },
    async downloadTicket() {
      for (const [index] of this.ticket.ticket_info.entries()) {
        this.indexTicket = index;
        this.isPdf = true;
        this.isDownloadTicket = true;
        await this.$refs.html2Pdf.generatePdf();
        this.showDownloadSuccessIcon = true;
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.showDownloadSuccessIcon = false;
      }
    },
  },
};
</script>
  
  <style scoped>
@media screen and (min-width: 1024px) {
  .tripCard {
    background: #fcfcfc;
    border-radius: 10px;
    width: 100%;
    height: max-content;
    padding: 20px 20px 20px 0px;
    border: 1.5px solid #efefef;
  }
  .tripCardDate {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1b1b1b;
  }
  .tripCardCity {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #1b1b1b;
    text-align: left;
  }
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #b5b5b5;
    margin: 2px;
  }
  .line {
    height: 1px;
    width: calc(100% - 32px);
    background: #b5b5b5;
  }
  .cardLine {
    background-color: transparent;
    width: 2px;
    height: 0;
    margin-right: 15px;
    margin-left: 5px;
    position: relative;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
  .tripCard:hover .cardLine {
    background-color: #149e51;
    height: 110px;
  }
  .differentHours {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #989898;
  }
  .detailBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #1b1b1b;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: rgba(20, 158, 81, 0.1);
    padding: 8px 12px;
  }
  .downloadBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #fafafa;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: #149e51;
    padding: 8px 12px;
  }
  .detailBtn:hover {
    background: #149e51;
    color: #fafafa;
  }
  .detailTripCard {
    width: calc(100% - 100px);
    height: max-content;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 20px 20px;
    margin-left: 50px;
    text-align: left;
  }
  .routeDetailInfoSectionName {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #1b1b1b;
    margin-bottom: 15px;
  }
  .routeSectionText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1b1b1b;
  }
  .detailRouteBtn {
    text-decoration-line: underline;
    color: #989898;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
  }
  .detailRouteBtn:hover {
    color: #149e51;
  }
  .helpIcon {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    background-color: #828282;
    -webkit-mask: url("@/assets/img/iconsSvg/helpIcon.svg") no-repeat center;
    mask: url("@/assets/img/iconsSvg/helpIcon.svg") no-repeat center;
  }
  .routeName {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1b1b1b;
  }
  .sectionName {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1b1b1b;
  }
  .sectionText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1b1b1b;
    padding-right: 16px;
  }
  .cancelRouteBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #870808;
    margin-right: 32px;
    margin-left: 32px;
    cursor: pointer;
    user-select: none;
  }
  .cancelRouteBtn:hover {
    text-decoration: underline;
    color: #bc0b0b;
  }
  .qrCodeBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    cursor: pointer;
    user-select: none;
    margin-left: 8px;
  }
  .qrCodeBtn:hover {
    text-decoration: underline;
    color: #149e51;
  }
  .searchNearbyTicket {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    cursor: pointer;
    user-select: none;
    margin-right: 32px;
  }
  .searchNearbyTicket:hover {
    color: #149e51;
    text-decoration: underline;
  }
  .ticketExpired {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding-top: 30px;
    text-align: center;
    color: #1b1b1b;
  }
}
@media screen and (max-width: 1023px) {
  .tripCard {
    background: #fcfcfc;
    border-radius: 10px;
    width: 100%;
    height: max-content;
    padding: 20px 20px 20px 20px;
    border: 1.5px solid #efefef;
  }
  .tripCardDate {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #1b1b1b;
  }
  .tripCardCity {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #1b1b1b;
    text-align: left;
    text-overflow: ellipsis;
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #b5b5b5;
    margin: 2px;
  }
  .line {
    height: 1px;
    width: calc(100% - 32px);
    background: #b5b5b5;
  }
  .differentHours {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #989898;
  }
  .detailBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #1b1b1b;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: rgba(20, 158, 81, 0.1);
    padding: 8px 12px;
  }
  .detailBtn:hover {
    background: #149e51;
    color: #fafafa;
  }
  .detailTripCard {
    width: calc(100% - 40px);
    height: max-content;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 20px 20px;
    margin-left: 20px;
    text-align: left;
  }
  .routeDetailInfoSectionName {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #1b1b1b;
    margin-bottom: 15px;
  }
  .routeSectionText {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1b1b1b;
  }
  .detailRouteBtn {
    text-decoration-line: underline;
    color: #989898;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
  }
  .detailRouteBtn:hover {
    color: #149e51;
  }
  .downloadBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #fafafa;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: rgba(20, 158, 81, 0.1);
    padding: 8px 12px;
    display: flex;
    justify-content: center;
  }
  .helpIcon {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    background-color: #828282;
    -webkit-mask: url("@/assets/img/iconsSvg/helpIcon.svg") no-repeat center;
    mask: url("@/assets/img/iconsSvg/helpIcon.svg") no-repeat center;
  }
  .routeName {
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #1b1b1b;
  }
  .sectionName {
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    color: #1b1b1b;
  }
  .sectionText {
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    color: #1b1b1b;
    padding-right: 16px;
  }
  .cancelRouteBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #870808;
    margin-right: 32px;
    cursor: pointer;
    user-select: none;
  }
  .cancelRouteBtn:hover {
    text-decoration: underline;
    color: #bc0b0b;
  }
  .qrCodeBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    cursor: pointer;
    user-select: none;
    margin-left: 8px;
  }
  .qrCodeBtn:hover {
    text-decoration: underline;
    color: #149e51;
  }
  .searchNearbyTicket {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    cursor: pointer;
    user-select: none;
    margin-right: 32px;
  }
  .searchNearbyTicket:hover {
    color: #149e51;
    text-decoration: underline;
  }
  .ticketExpired {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding-top: 30px;
    text-align: center;
    color: #1b1b1b;
  }
}
.downloadIcon {
  width: 24px;
  height: 24px;
  background-color: #eeeeee;
  -webkit-mask: url("@/assets/img/iconsSvg/downloadIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/downloadIcon.svg") no-repeat center;
}
.tripCard:hover .routeName {
  color: #149e51;
}
</style>
<style>
.theme--light.v-icon,
.theme--dark.v-icon {
  color: inherit !important;
}
</style>