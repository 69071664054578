<template>
  <modalUI
    v-if="showResetPasswordModal"
    :visible="showResetPasswordModal"
    modalTitle="Відновлення паролю"
    @close="$emit('close')"
  >
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <v-row no-gutters justify="center" v-on:keyup.enter="resetPassword">
      <v-col class="px-0 py-0" cols="8" style="text-align: center">
        <p class="modalSubtitle">
          Забули пароль? Не проблема - відновіть його зараз!
        </p>
        <v-row
          no-gutters
          justify="center"
          style="margin-top: 40px"
          v-if="!isSendEmail"
        >
          <v-col cols="10" class="py-0 px-0" style="text-align: center">
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Email"
              class="textField"
              height="50px"
              :success="!$v.email.$invalid"
              v-model="email"
              :error-messages="emailError"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-else no-gutters justify="center">
          <v-col class="px-0 py-0" style="text-align: center">
            <h2
              style="
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                letter-spacing: 0.1em;
                color: #243949;
              "
            >
              Запит успішно прийнятий
            </h2>
            <br />
            <p
              class="mx-auto"
              style="
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.1em;
                color: #243949;
                text-align: left;
              "
            >
              На пошту
              <strong
                >{{ email.substr(0, 3) }}***{{
                  email.substr(email.length - 14, email.length - 1)
                }}</strong
              >, вказану під час реєстрації, зараз прийде лист. У ньому буде
              посилання, по якому слід перейти, щоб Ви змогли створити новий
              пароль.<br /><br />
              <strong>Дуже важливо</strong> не забути перевірити папку "СПАМ" у
              своїй поштовій скриньці, якщо лист довго не надходить!
            </p>
          </v-col>
        </v-row>
        <v-col class="py-0 px-0" style="text-align: center">
          <modal-btn
            width="400px"
            :disabled="$v.$invalid"
            style="margin-top: 40px; margin-bottom: 12px"
            @click="isSendEmail ? $emit('close') : resetPassword()"
            >{{ isSendEmail ? "Закрити" : "Відновити пароль" }}
          </modal-btn>
          <br v-if="!isSendEmail" />
          <modal-cancel-btn v-if="!isSendEmail" @click="$emit('close')" />
        </v-col>
      </v-col>
    </v-row>
  </modalUI>
</template>

<script>
import modalUI from "@/components/UI/modalUI";
import ModalBtn from "@/components/UI/modalBtn.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ModalCancelBtn from "@/components/UI/modalCancelBtn.vue";
import authService from "@/requests/admin/authService";
import ErrorSnackbar from "@/components/UI/Snackbars/errorSnackbar.vue";
export default {
  mixins: [validationMixin],
  components: {
    ModalBtn,
    modalUI,
    ModalCancelBtn,
    ErrorSnackbar,
  },
  data: () => ({
    email: "",
    isSendEmail: false,
    showErrorSnackbar: false,
    snackbarText: "",
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  props: {
    showResetPasswordModal: {
      require: true,
    },
  },
  methods: {
    async resetPassword() {
      this.$v.$touch();
      this.showErrorSnackbar = false;
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("email", this.email);
        await authService
          .resetPassword(form)
          .then((res) => {
            if (res.status == "Success") {
              localStorage.setItem("userEmail", this.email);
              this.isSendEmail = true;
            }
          })
          .catch(() => {
            this.snackbarText = "Користувача з таким email не існує";
            this.showErrorSnackbar = true;
          });
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.required && errors.push("");
      if (!this.$v.email.email) {
        errors.push("");
        return errors;
      }
      return errors;
    },
  },
};
</script>

<style>
</style>