<template>
  <div>
    <v-row no-gutters align="center" justify="center" v-if="pageCount > 10">
      <div class="paginationCard" @click="activePage > 1 ? activePage-- : 1">
        <div class="arrowIcon" />
      </div>
      <div class="paginationPageCount" style="margin: 0px 20px">
        <!-- THE FIRST 4 PAGES -->
        <div v-for="page in pageCount" :key="page">
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="activePage == 1 && (page == 1 || page == 2 || page == 3)"
          >
            {{ page }}
          </div>
        </div>
        <!-- STATIC FIRST PAGE IN PAGINATION -->
        <div v-for="page in pageCount" :key="page">
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="activePage > 2 && page == 1"
          >
            {{ page }}
          </div>
        </div>
        <!-- THREE DOTS IF ACTIVE PAGE > 2 -->
        <div style="margin-right: 10px" v-if="activePage > 2">...</div>
        <div v-for="page in pageCount" :key="page" v-show="activePage !== 1">
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="
              (activePage - 1 == page ||
                activePage == page ||
                activePage + 1 == page) &&
              activePage <= pageCount - 2
            "
          >
            {{ page }}
          </div>
        </div>
        <!-- THREE DOTS IF COUNT OF PAGE > 10 -->
        <div
          style="margin-right: 10px"
          v-if="pageCount > 10 && activePage <= pageCount - 2"
        >
          ...
        </div>
        <div v-for="page in pageCount" :key="page" v-show="activePage < 3">
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="
              page == pageCount ||
              page == pageCount - 1 ||
              page == pageCount - 2
            "
          >
            {{ page }}
          </div>
        </div>
        <!-- ALL OTHER PAGE LIST -->
        <div
          v-for="page in pageCount"
          :key="page"
          v-show="activePage >= 3 && activePage <= pageCount - 2"
        >
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="page == pageCount"
          >
            {{ page }}
          </div>
        </div>

        <div
          v-for="page in pageCount"
          :key="page"
          v-show="activePage > pageCount - 2"
        >
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
            v-if="
              pageCount - 2 == page ||
              pageCount - 1 == page ||
              pageCount == page
            "
          >
            {{ page }}
          </div>
        </div>
      </div>
      <div
        class="paginationCard"
        style="transform: scaleX(-1)"
        @click="activePage < pageCount ? activePage++ : ''"
      >
        <div class="arrowIcon" />
      </div>
    </v-row>
    <v-row no-gutters align="center" v-else>
      <div class="paginationCard" @click="activePage > 1 ? activePage-- : 1">
        <div class="arrowIcon" />
      </div>
      <div class="paginationPageCount" style="margin: 0px 20px">
        <!-- THE FIRST 4 PAGES -->
        <div v-for="page in pageCount" :key="page">
          <div
            class="countCard"
            :class="activePage == page ? 'activeCountCard' : ''"
            @click="activePage = page"
          >
            {{ page }}
          </div>
        </div>
      </div>
      <div
        class="paginationCard"
        style="transform: scaleX(-1)"
        @click="activePage < pageCount ? activePage++ : ''"
      >
        <div class="arrowIcon" />
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  data: () => ({
    activePage: 1,
  }),
  props: {
    pageCount: {
      require: true,
    },
  },
  watch: {
    activePage: {
      handler() {
        this.$emit("changePage", this.activePage);
      },
    },
  },
};
</script>

<style scoped>
.paginationCard {
  width: max-content;
  height: 56px;
  padding: 28px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}
.paginationPageCount {
  max-width: 70svh;
  width: max-content;
  height: 56px;
  padding: 28px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.paginationCard:hover {
  background: #1b1b1b;
}
.paginationCard:hover .arrowIcon {
  background-color: #149e51;
}
.countCard {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1b1b1b;
  margin-right: 10px;
  user-select: none;
}
.activeCountCard {
  background: #1a2627;
  color: #eeeeee;
}
.countCard:nth-last-child {
  margin-right: 0px;
}
.arrowIcon {
  width: 24px;
  height: 10px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/iconsSvg/arrowLeft.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/arrowLeft.svg") no-repeat center;
}
</style>