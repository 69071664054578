import requestService from "../requestService";
import store from '@/store';

export default {
	async createCity(form) {
		const response = await requestService.post(`/admin/city`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateCity(id, form) {
		const response = await requestService.post(`/admin/city/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getCities(page) {
		const response = await requestService.get(`/admin/city?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async searchCities(search) {
		const response = await requestService.get(`/admin/city/search/${search}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getAllCity() {
		const response = await requestService.get(`/admin/city/all`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getUkraineCity() {
		const response = await requestService.get(`/admin/city/ukraine`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getForeignCity() {
		const response = await requestService.get(`/admin/city/foreign`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getCity(id) {
		const response = await requestService.get(`/admin/city/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deleteCity(id) {
		const response = await requestService.delete(`/admin/city/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async createCountry(form) {
		const response = await requestService.post(`/admin/country`, form)
		return response?.data
	},
	async updateCountry(id, form) {
		const response = await requestService.post(`/admin/country/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getCountries() {
		const response = await requestService.get(`/admin/country?page=7`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data.data
	},
	async searchCountry(search) {
		const response = await requestService.get(`/admin/country/search/${search}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deleteCountry(id) {
		const response = await requestService.get(`/admin/country/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
}