export default {
    data: () => ({
        deparuteDate: new Date(),
        arrivalDate: new Date(),
    }),
    methods: {
        setDifferentTime(departure_date, arrival_date) {
            let diff_time = (
                Math.abs(new Date(departure_date) - new Date(arrival_date)) / 1000 / 60 / 60
            ).toString();
            diff_time = new Intl.NumberFormat("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(diff_time);
            let different_time = diff_time.length >= 5
                ? `${diff_time.substr(0, 2)} год ${Math.floor(parseFloat(diff_time.substr(3, 4)) / 1.66)} хв`
                : `${diff_time.substr(0, 1) !== '0' ? `${diff_time.substr(0, 1)} год` : ''} ${Math.floor(parseFloat(diff_time.substr(2, 4)) / 1.66)} хв`;

            return different_time;
        },
        addMinutes(time, minutesToAdd) {
            const [hours, minutes] = time.split(":").map(Number);
            let totalMinutes = hours * 60 + minutes + minutesToAdd;
            const updatedHours = Math.floor(totalMinutes / 60);
            const updatedMinutes = totalMinutes % 60;
            const formattedHours = updatedHours.toString().padStart(2, "0");
            const formattedMinutes = updatedMinutes.toString().padStart(2, "0");

            return `${formattedHours}:${formattedMinutes}`;
        },
        setTripTime() {
            this.deparuteDate = this.ticket?.info?.route_info?.route_city.filter(
                (city) => city.city_id == this.ticket?.departure_city
            )?.[0];
            this.arrivalDate = this.ticket?.info?.route_info?.route_city.filter(
                (city) => city.city_id == this.ticket?.arrival_city
            )?.[0];
            const startDepartureDate = new Date(this.ticket?.info?.departed_at);
            const startArrivalDate = new Date(this.ticket?.info?.departed_at);
            const minutesToAddDeparture = this.deparuteDate?.time_from_start;
            const minutesToAddArrival = this.arrivalDate?.time_from_start;
            startDepartureDate.setMinutes(
                startDepartureDate.getMinutes() + minutesToAddDeparture
            );
            startArrivalDate.setMinutes(
                startArrivalDate.getMinutes() + minutesToAddArrival
            );

            this.deparuteDate = startDepartureDate;
            this.arrivalDate = startArrivalDate;
        },
        addMinutesToDate(date, minutes) {
            return new Date(new Date(date).getTime() + minutes * 60000);
        },
        formatDateToCustomISO(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Додаємо 0 перед місяцем, якщо він одноцифровий
            const day = String(date.getDate()).padStart(2, '0'); // Додаємо 0 перед днем, якщо він одноцифровий

            const customISO = `${year}-${month}-${day}`;
            return customISO;
        }
    }
}