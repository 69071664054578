<template>
  <modalUI
    v-if="showLoginModal"
    :visible="showLoginModal"
    modalTitle="Вхід"
    @close="$emit('close')"
  >
    <error-snackbar
      v-if="showErrorSnackbar"
      snackbarText="Не правильно введено емайл або пароль"
    />
    <v-row no-gutters justify="center" v-on:keyup.enter="login">
      <v-col class="px-0 py-0" cols="8" style="text-align: center">
        <p class="modalSubtitle">
          Щоб продовжити купівлю квитків, увійдіть в кабінет користувача
        </p>
        <v-row no-gutters justify="center" style="margin-top: 40px">
          <v-col cols="10" class="py-0 px-0" style="text-align: center">
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Email"
              class="textField"
              height="50px"
              :success="!$v.user.email.$invalid"
              v-model="user.email"
              :error-messages="emailError"
            />
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Пароль"
              class="textField"
              height="50px"
              type="password"
              :success="!$v.user.password.$invalid"
              v-model="user.password"
              :error-messages="passwordError"
            />
            <v-row no-gutters>
              <input type="checkbox" style="margin-right: 8px" />
              <span class="optionText" style="color: #656565"
                >Запам’ятати мене</span
              >
              <v-row no-gutters justify="end">
                <span
                  class="resetPasswordText"
                  style="color: #149e51"
                  @click="$emit('resetPassword')"
                  >Забули пароль?</span
                >
              </v-row>
            </v-row>
            <modal-btn
              width="400px"
              :disabled="$v.$invalid"
              style="margin-top: 40px"
              @click="login"
              >Увійти</modal-btn
            >
            <v-row
              no-gutters
              align="center"
              style="margin-top: 24px; margin-bottom: 20px"
            >
              <v-divider style="border: 0.5px solid rgba(27, 27, 27, 0.3)" />
              <span
                style="
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.1em;
                  color: #1b1b1b;
                  margin: 8px;
                "
                >або</span
              >
              <v-divider style="border: 0.5px solid rgba(27, 27, 27, 0.3)" />
            </v-row>
            <v-row no-gutters justify="center">
              <span
                class="goToRegistration pointer"
                @click="$emit('goToRegistration')"
                >Реєстрація</span
              >
            </v-row>
            <button
              @click="openGoogleSignInModal"
              data-onsuccess="onGoogleSignInSuccess"
              class="google-signin-button"
            >
              <img
                width="24px"
                height="24px"
                style="margin-right: 10px"
                src="@/assets/img/googleIcon.png"
                alt="google icon"
              />
              Увійти через Google
            </button>
            <fb-signin-button
              :params="fbSignInParams"
              @success="onSignInSuccess"
              @error="onSignInError"
            >
              <img
                width="24px"
                height="24px"
                style="margin-right: 10px"
                src="@/assets/img/facebookIcon.png"
                alt="google icon"
              />
              Увійти через Facebook
            </fb-signin-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </modalUI>
</template>
<script>
import modalUI from "@/components/UI/modalUI.vue";
import ModalBtn from "@/components/UI/modalBtn.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authService from "@/requests/admin/authService";
import ErrorSnackbar from "@/components/UI/Snackbars/errorSnackbar.vue";
import signinWithSocialsMixin from "@/mixins/signinWithSocialsMixin";
export default {
  mixins: [validationMixin, signinWithSocialsMixin],
  components: { modalUI, ModalBtn, ErrorSnackbar },
  data: () => ({
    user: {
      username: "",
      password: "",
    },
    showErrorSnackbar: false,
  }),
  props: {
    showLoginModal: {
      require: true,
    },
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("email", this.user.email);
        form.append("password", this.user.password);
        await authService
          .signIn(form)
          .then((res) => {
            if (res.status == "Success") {
              this.updateInfoLogged({
                first_name: res.data.user_info.first_name,
                last_name: res.data.user_info.last_name,
                email: res.data.user_info.email,
                role_name: res.data.user_info.role_name,
                count_ticket: res.data.user_info.count_ticket,
                token: res.data.token,
              });
              this.user = {};
              this.$v.$reset();
              this.$emit("close");
            }
          })
          .catch(() => {
            this.showErrorSnackbar = true;
            setTimeout(() => {
              this.showErrorSnackbar = false;
            }, 3000);
          });
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push("");
      if (!this.$v.user.email.email) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.goToRegistration {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #1b1b1b;
}
.goToRegistration:hover {
  color: #149e51;
}
.textField {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656565;
  border-radius: 10px !important;
}
.optionText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
}
.resetPasswordText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #149e51;
  cursor: pointer;
}
.resetPasswordText:hover {
  text-decoration-line: underline;
  color: #106634 !important;
}
input[type="checkbox"] {
  accent-color: #149e51;
}
</style>