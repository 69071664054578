<template>
  <v-dialog v-model="visibility" width="380px">
    <div class="modalBackground">
      <p class="modalTitle">
        {{ modalTitle }}
      </p>
      <p class="modalText">
        {{ modalText }}
      </p>
      <modal-btn
        width="100%"
        style="background: #bc0b0b !important; margin-top: 24px; margin-bottom: 12px;"
        @click="$emit('confirm')"
        >Відмінити</modal-btn
      >
      <modal-cancel-btn @click="$emit('close')" />
    </div>
  </v-dialog>
</template>
  
  <script>
import modalMixin from "@/mixins/modalMixin";
import modalCancelBtn from "../modalCancelBtn.vue";
import ModalBtn from "../modalBtn.vue";
export default {
  components: { modalCancelBtn, ModalBtn },
  mixins: [modalMixin],
  props: {
    modalTitle: {
      require: true,
    },
    modalText: {
      require: false,
    },
  },
};
</script>
  
  <style scoped>
.modalBackground {
  background: #fcfcfc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: max-content;
  padding: 32px;
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
.modalText {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #1b1b1b;
  margin-bottom: 0px;
}
</style>