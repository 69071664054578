<template>
  <modalUI
    :visible="showMyTicketModal"
    width="1180px"
    modalTitle="Ваші квитки"
    @close="$emit('close')"
  >
    <my-ticket-content @close="$emit('close')" />
  </modalUI>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import ModalUI from "@/components/UI/modalUI.vue";
import MyTicketContent from "./myTicketContent.vue";
export default {
  mixins: [modalMixin],
  components: {
    ModalUI,
    MyTicketContent,
  },
  data: () => ({}),
  props: {
    showMyTicketModal: {
      require: true,
    },
  },
};
</script>

<style scoped>
</style>