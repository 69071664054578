<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="12"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 32px'"
    >
      <step-to-discount
        style="
          background: #fcfcfc;
          box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
          padding: 16px;
        "
        v-if="!$vuetify.breakpoint.smAndDown"
      />
      <div
        v-else
        style="
          background: rgba(20, 158, 81, 0.05);
          padding: 16px 20px;
          margin-bottom: 20px;
          border-radius: 10px;
        "
        @click="showDiscountStepModal = true"
      >
        <v-row no-gutters align="center">
          <v-progress-circular
            :value="20 * buyedTicket"
            color="#149E51"
            background-color="#DAE0EE"
            :size="52"
            style="margin-right: 8px"
          >
            {{ buyedTicket }}/5
          </v-progress-circular>
          <v-col class="px-0 py-0">
            <p
              style="
                margin-bottom: 0px;
                color: #1b1b1b;
                font-family: 'MacPaw Fixel Display';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
              "
            >
              Знижка 10%
            </p>
            <span
              style="
                color: #4f545a;
                font-family: 'MacPaw Fixel Display';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              "
              >Отримайте знижку на купівлю 6 квитка</span
            >
          </v-col>
          <v-col cols="1" class="py-0" style="text-align: center">
            <img
              src="@/assets/img/iconsSvg/chevronIcon.svg"
              alt="right"
              height="16px"
              style="transform: rotate(180deg)"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col
      cols="12"
      xl="8"
      lg="8"
      md="8"
      sm="12"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'padding-bottom:150px!important; padding: 0px 20px!important'
          : ''
      "
    >
      <v-row
        no-gutters
        align="center"
        :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        style="margin-bottom: 32px"
      >
        <div
          class="tab"
          style="margin-right: 16px"
          :class="activeTab == 'active' ? 'activeTab' : 'inActiveTab'"
          @click="activeTab = 'active'"
        >
          Активні
        </div>
        <div
          class="tab"
          :class="activeTab == 'inactive' ? 'activeTab' : 'inActiveTab'"
          @click="activeTab = 'inactive'"
        >
          Неактивні
        </div>
      </v-row>
      <Loader v-if="showLoader" />
      <v-col class="py-0 px-0" v-else>
        <v-row v-if="!ticketList.length" no-gutters justify="center">
          <v-col
            class="px-0 py-0"
            cols="12"
            xl="4"
            lg="4"
            md="6"
            sm="12"
            style="text-align: center; margin-top: 34px"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-bottom: 0px;'
                : 'margin-bottom: 56px;'
            "
          >
            <img
              src="@/assets/img/noRoutesImg.png"
              alt="no ticket"
              width="280px"
              height="241px"
              style="margin-bottom: 24px"
            />
            <br />
            <span class="emptyList">Ваш список порожній</span>
          </v-col>
        </v-row>
        <v-col v-else class="px-0 py-0">
          <ticket-card
            v-for="ticket in ticketList"
            :key="ticket.uuid"
            :ticket="ticket"
            :isActive="activeTab == 'active' ? true : false"
            @close="$emit('close')"
          />
        </v-col>
      </v-col>
      <v-row no-gutters justify="center" v-if="pageCount > 1">
        <pagination :pageCount="pageCount" @changePage="getOrders" />
      </v-row>
      <v-row no-gutters align="center" justify="center">
        <modal-btn
          width="280px"
          style="margin-top: 32px"
          @click="$emit('close'), $router.push('/')"
          >Пошук маршруту</modal-btn
        >
      </v-row>
    </v-col>
    <discount-step-modal
      v-if="showDiscountStepModal"
      :visible="showDiscountStepModal"
      @close="showDiscountStepModal = false"
    />
  </v-row>
</template>

<script>
import modalBtn from "@/components/UI/modalBtn.vue";
import TicketCard from "./ticketCard.vue";
import bookingTicketService from "@/requests/main/bookingTicketService";
import pagination from "@/components/UI/pagination.vue";
import Loader from "@/components/UI/Loader.vue";
import StepToDiscount from "./stepToDiscount.vue";
import DiscountStepModal from "./discountStepModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    modalBtn,
    TicketCard,
    pagination,
    Loader,
    StepToDiscount,
    DiscountStepModal,
  },
  data: () => ({
    activeTab: "active",
    ticketList: [],
    page: 1,
    buyedTicket: 0,
    pageCount: 0,
    showLoader: true,
    showDiscountStepModal: false,
  }),
  mounted() {
    this.getOrders(this.page);
    this.buyedTicket = this.loggedUser.count_ticket;
  },
  methods: {
    async getOrders(page) {
      this.page = page;
      await bookingTicketService
        .getOrders(this.activeTab, page)
        .then((res) => {
          if (res.status == "Success") {
            this.pageCount = res.pagination.meta.last_page;
            this.ticketList = res.data;
            this.showLoader = false;
          }
        })
        .catch(() => {
          this.ticketList = [];
          this.pageCount = 0;
          this.showLoader = false;
        });
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    activeTab: {
      deep: true,
      handler() {
        this.showLoader = true;
        this.getOrders(1);
      },
    },
  },
};
</script>

<style scoped>
.myTicketBackground {
  width: 1180px;
  height: max-content;
  padding: 32px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.modalTitle {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #149e51;
  margin-bottom: 32px;
}
.tab {
  height: 42px;
  width: max-content;
  padding: 10px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .tab {
    width: calc(50% - 16px) !important;
  }
}
.activeTab {
  background-color: #303030;
  color: #eeeeee;
}
.inActiveTab {
  background-color: #d8d8d8;
  color: #1b1b1b;
}
.inActiveTab:hover {
  background: #9d9d9d !important;
}
.emptyList {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #828282;
}
</style>