<template>
  <v-dialog v-model="visibility" width="380px">
    <div class="modalBackground">
      <p style="margin-bottom: 24px" class="modalTitle">
        Ваш електронний квиток
      </p>
      <p class="modalText">Відскануйте QR-код</p>
      <v-row no-gutters justify="center">
        <div style="width: 250px; height: 250px; margin-bottom: 24px">
          <VueQRCodeComponent
            :text="text"
            :size="250"
            color="#149E51"
            render-as="svg"
          />
        </div>
      </v-row>
      <!-- <v-row no-gutters align="center" style="margin-bottom: 16px">
        <v-divider style="border: 0.5px solid #b9b9b9" />
        <span
          style="
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.1em;
            color: #1b1b1b;
            margin-left: 10px;
            margin-right: 10px;
          "
          >або</span
        >
        <v-divider style="border: 0.5px solid #b9b9b9" />
      </v-row>
      <div
        class="qrCodeModalBtn"
        @click="sendTicket"
        :style="
          isSendEmail
            ? 'background: #149E51;border-color:#106634;pointer-events: none;'
            : ''
        "
      >
        <span class="btnText" v-if="!isSendEmail && !showSendEmailLoader">
          Надіслати на Email</span
        >
        <v-progress-circular
          v-if="showSendEmailLoader"
          indeterminate
          color="#149E51"
          :size="30"
          :width="2"
        />
        <v-icon
          color="white"
          v-if="showEmailSuccessIcon && isSendEmail"
          class="successIcon"
          >mdi-check-circle-outline</v-icon
        >
        <span class="successBtnText" v-if="!showEmailSuccessIcon && isSendEmail"
          >Надіслано</span
        >
      </div>
      <div
        class="qrCodeModalBtn"
        :style="
          isDownloadTicket
            ? 'background: #149E51;border-color:#106634;pointer-events: none;'
            : ''
        "
        @click="downloadTicket"
      >
        <span class="btnText" v-if="!isDownloadTicket"
          >Завантажити PDF квиток</span
        >
        <v-icon
          color="white"
          v-if="showDownloadSuccessIcon && isDownloadTicket"
          class="successIcon"
          >mdi-check-circle-outline</v-icon
        >
        <span
          class="successBtnText"
          v-if="!showDownloadSuccessIcon && isDownloadTicket"
          >Завантажено</span
        >
      </div> -->
      <modal-cancel-btn
        style="margin-top: 0px"
        text="Скасувати"
        @click="$emit('close')"
      />
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="`Квиток ${ticket?.info?.route_info?.departure_info.name} - ${
        ticket?.info?.route_info?.destination_info.name
      } ${new Date(ticket?.info?.departed_at.substr(0, 10)).toLocaleDateString(
        'uk-UA',
        { month: 'short', day: 'numeric' }
      )}  ${ticket?.ticket_info[indexTicket]?.first_name} 
      ${ticket?.ticket_info[indexTicket]?.last_name}`"
      :pdf-quality="2"
      :manual-pagination="true"
      :paginate-elements-by-height="200"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          id="pdfTicket"
          ref="pdfTicket"
          :ticket="ticket"
          :isPdf="isPdf"
          :text="qrText"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </v-dialog>
</template>
  
  <script>
import modalMixin from "@/mixins/modalMixin";
import ModalCancelBtn from "../modalCancelBtn.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import pdfTicket from "@/components/User/pdfTicket.vue";
import VueHtml2pdf from "vue-html2pdf";
import bookingTicketService from "@/requests/main/bookingTicketService";
export default {
  components: { ModalCancelBtn, VueQRCodeComponent, pdfTicket, VueHtml2pdf },
  mixins: [modalMixin],
  data: () => ({
    isSendEmail: false,
    isDownloadTicket: false,
    showEmailSuccessIcon: false,
    showDownloadSuccessIcon: false,
    isPdf: false,
    showSendEmailLoader: false,
    indexTicket: 0,
    qrText: "",
  }),
  props: {
    text: {
      require: true,
    },
    ticket: {
      require: true,
    },
  },
  mounted() {
    this.setQrText();
    this.setCity();
  },
  methods: {
    async sendTicket() {
      this.isPdf = false;
      this.showSendEmailLoader = true;
      let response = await bookingTicketService.sendOrderToEmail(
        this.ticket.uuid
      );
      if (response.status == "Success") {
        this.isSendEmail = true;
        this.showSendEmailLoader = false;
        this.showEmailSuccessIcon = true;
        setTimeout(() => {
          this.showEmailSuccessIcon = false;
        }, 3000);
      }
    },
    async downloadTicket() {
      for (const [index] of this.ticket.ticket_info.entries()) {
        this.indexTicket = index;
        this.isPdf = true;
        this.isDownloadTicket = true;
        await this.$refs.html2Pdf.generatePdf();
        this.showDownloadSuccessIcon = true;
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.showDownloadSuccessIcon = false;
      }
    },
    setQrText() {
      this.qrText = `${location.protocol}//${location.host}/order_detail/${this.ticket.uuid}`;
    },
    setCity() {
      this.departure_city = this.ticket.info.route_info.departure_info;
      this.arrival_city = this.ticket.info.route_info.arrival_info;
    },
  },
};
</script>
  
  <style scoped>
.modalBackground {
  background: #fcfcfc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: max-content;
  padding: 32px;
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
.modalText {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
.qrCodeModalBtn {
  height: 50px;
  width: 100%;
  background: #fcfcfc;
  border: 2px solid #149e51;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  user-select: none;
  cursor: pointer;
}
.btnText {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #1b1b1b;
}
.btnText:hover {
  text-decoration: underline;
  color: #149e51;
}
.successBtnText {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eeeeee;
}
.successIcon {
  -webkit-animation: spin 1s linear, 1s;
  -moz-animation: spin 1s linear, 1s;
  animation: spin 1s linear, 1s;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>