<template>
    <v-snackbar top v-model="snackbar" color="error">
      <v-row align="center" justify="center">
        <v-icon>mdi-alert-circle-outline</v-icon>
        <span style="margin-left: 10px">{{ snackbarText }}</span>
      </v-row>
    </v-snackbar>
  </template>
  
  <script>
  export default {
    data: () => ({
      snackbar: true,
    }),
    props: {
      snackbarText: {
        require: true,
      },
    },
  };
  </script>
  
  <style>
  </style>