<template>
  <div id="app">
    <v-app>
      <Header />
      <router-view />
      <mobile-bottom-menu v-if="$vuetify.breakpoint.smAndDown" />
      <Footer />
    </v-app>
    <scammers-dialog
      v-if="showScammersDialog"
      :visible="showScammersDialog"
      @close="showScammersDialog = false"
    />
  </div>
</template> 

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import mobileBottomMenu from "./components/User/mobileBottomMenu.vue";
import { mapActions } from "vuex";
import ScammersDialog from "./components/UI/Modals/scammersDialog.vue";
export default {
  name: "App",
  data: () => ({
    showScammersDialog: false,
  }),
  components: {
    Header,
    Footer,
    mobileBottomMenu,
    ScammersDialog,
  },
  mounted() {
    this.getSettingList();
    if (!localStorage.getItem("isShowScammersDialog")) {
      this.showScammersDialog = true;
      localStorage.setItem("isShowScammersDialog", true);
    }
  },
  methods: {
    ...mapActions(["getSettingList"]),
  },
};
</script>

<style>
#app {
  font-family: "MacPaw Fixel Display";
  font-style: normal;
  overflow-x: hidden;
}
@font-face {
  font-family: "MacPaw Fixel Display";
  src: url("@/assets/fonts/FixelDisplay/FixelDisplay-Medium.woff2")
      format("woff2"),
    url("@/assets/fonts/FixelDisplay/FixelDisplay-Light.woff2") format("woff2"),
    url("@/assets/fonts/FixelDisplay/FixelDisplay-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
.v-text-field {
  z-index: 100;
}
.v-menu {
  z-index: 101;
}
</style>
