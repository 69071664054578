<template>
  <v-app-bar height="100px" width="100%" class="header" color="transparent">
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <v-col
            cols="12"
            xl="2"
            lg="2"
            md="2"
            sm="12"
            :style="$vuetify.breakpoint.smAndDown ? 'text-align:center' : ''"
          >
            <img
              src="@/assets/img/siteLogo.png"
              alt="site_logo"
              width="170px"
              height="40px"
            />
          </v-col>
          <v-col cols="8" v-if="!$vuetify.breakpoint.smAndDown">
            <v-row no-gutters align="center" justify="center">
              <span
                v-for="item in headerItem"
                :key="item.id"
                v-show="
                  !loggedUser
                    ? !item.for_admin
                    : loggedUser.role_name == 'guest'
                    ? !item.for_admin
                    : item
                "
                style="user-select: none"
              >
                <router-link
                  v-if="item.path"
                  :to="item.path"
                  class="headerMenuItem headerMenuInactiveItem"
                >
                  {{ item.title }}</router-link
                >
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="headerMenuItem"
                      :class="
                        item.path == $route.path ? '' : 'headerMenuInactiveItem'
                      "
                    >
                      {{ item.title }}
                    </span>
                  </template>
                  <v-card class="subitemsCard">
                    <v-col class="px-0 py-0">
                      <div
                        class="subitemsText"
                        v-for="subitem in item.subitems"
                        :key="subitem.id"
                      >
                        <router-link :to="subitem.path">
                          {{ subitem.title }}
                        </router-link>
                      </div>
                    </v-col>
                  </v-card>
                </v-menu>
              </span>
            </v-row>
          </v-col>
          <v-col cols="2" v-if="!$vuetify.breakpoint.smAndDown">
            <v-row no-gutters align="center" v-if="!loggedUser">
              <span
                class="subitemsText"
                style="margin-right: 20px; margin-bottom: 0px"
                @click="showRegistrationModal = true"
              >
                Реєстрація
              </span>
              <v-btn
                class="loginBtn"
                width="80px"
                height="25px"
                @click="showLoginModal = true"
              >
                Вхід
              </v-btn>
            </v-row>
            <v-menu offset-y v-model="profileDropdown" v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="loginBtn"
                  :class="profileDropdown ? 'loginBtnActive' : ''"
                  width="167px"
                  height="35px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="profileIcon" />
                  Ваш кабінет
                </v-btn>
              </template>
              <v-card class="profileDropdown">
                <v-row
                  no-gutters
                  align="center"
                  style="
                    border-bottom: 1px solid white;
                    padding-bottom: 8px;
                    border-radius: 0px;
                  "
                >
                  <v-col
                    class="px-0 py-0"
                    cols="10"
                    style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                  >
                    <span class="profileUsernameText"
                      >{{ loggedUser.first_name }}
                      {{ loggedUser.last_name }}</span
                    >
                  </v-col>
                  <v-row no-gutters justify="end">
                    <img
                      src="@/assets/img/iconsSvg/logoutIcon.svg"
                      alt="logout"
                      class="pointer"
                      @click="showConfirmLogoutModal = true"
                    />
                  </v-row>
                </v-row>
                <v-row
                  no-gutters
                  align="center"
                  style="margin-top: 18.5px"
                  class="profileDropdownText pointer"
                  @click="showMyTicketModal = true"
                >
                  <div class="profileDropdownIconTicket" alt="tickets" />
                  <span>Ваші квитки</span>
                </v-row>
                <v-row
                  no-gutters
                  align="center"
                  style="margin-top: 18px"
                  class="profileDropdownText pointer"
                  @click="showProfileModal = true"
                >
                  <div class="profileDropdownIconSetting" alt="setting" />
                  <span>Налаштування</span>
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- LOGIN MODAL -->
    <login-modal
      v-if="showLoginModal"
      :showLoginModal="showLoginModal"
      @goToRegistration="
        (showLoginModal = false), (showRegistrationModal = true)
      "
      @resetPassword="(showLoginModal = false), (showResetPasswordModal = true)"
      @close="showLoginModal = false"
    />
    <!-- REGISTRATION MODAL -->
    <registration-modal
      v-if="showRegistrationModal"
      :showRegistrationModal="showRegistrationModal"
      @goToSignIn="(showRegistrationModal = false), (showLoginModal = true)"
      @close="showRegistrationModal = false"
    />
    <!-- RESET PASSWORD MODAL -->
    <reset-password-modal
      v-if="showResetPasswordModal"
      :showResetPasswordModal="showResetPasswordModal"
      @close="showResetPasswordModal = false"
    />
    <!-- LOGOUT MODAL -->
    <logout-modal
      v-if="showConfirmLogoutModal"
      :showConfirmLogoutModal="showConfirmLogoutModal"
      @close="showConfirmLogoutModal = false"
    />
    <!-- PROFILE MODAL -->
    <profile-modal
      v-if="showProfileModal"
      :showProfileModal="showProfileModal"
      @close="showProfileModal = false"
    />
    <!-- MY TICKET MODAL -->
    <my-ticket-modal
      v-if="showMyTicketModal"
      :showMyTicketModal="showMyTicketModal"
      @close="showMyTicketModal = false"
    />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import LoginModal from "./User/Auth/desktop/loginModal.vue";
import LogoutModal from "./User/Auth/desktop/logoutModal.vue";
import ProfileModal from "./User/Auth/desktop/profileModal.vue";
import RegistrationModal from "./User/Auth/desktop/registrationModal.vue";
import ResetPasswordModal from "./User/Auth/desktop/resetPassword/resetPasswordModal.vue";
import MyTicketModal from "./User/myTicket/myTicketModal.vue";
export default {
  components: {
    LoginModal,
    RegistrationModal,
    LogoutModal,
    ProfileModal,
    MyTicketModal,
    ResetPasswordModal,
  },
  name: "HeaderComponent",
  data: () => ({
    headerItem: [
      {
        id: 1,
        title: "Головна",
        path: "/",
        for_admin: false,
      },
      {
        id: 2,
        title: "Для пасажирів",
        subitems: [
          {
            id: 21,
            title: "Наші маршрути",
            path: "/routes",
          },
          {
            id: 22,
            title: "Нерегулярні перевезення",
            path: "/irregular_transportation",
          },
        ],
        for_admin: false,
      },
      {
        id: 3,
        title: "Автопарк",
        path: "/car_park",
        for_admin: false,
      },
      {
        id: 4,
        title: "Контакти",
        path: "/contact",
        for_admin: false,
      },
      {
        id: 5,
        title: "Адмін",
        path: "/admin",
        for_admin: true,
      },
    ],
    profileDropdown: false,
    showLoginModal: false,
    showRegistrationModal: false,
    showResetPasswordModal: false,
    showConfirmLogoutModal: false,
    showProfileModal: false,
    showMyTicketModal: false,
  }),
  mounted() {
    setTimeout(() => {
      if (this.$route.query.showModal == "true") {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$router.push("/tickets");
        } else {
          this.showMyTicketModal = true;
        }
      }
    }, 500);
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    showMyTicketModal: {
      handler() {
        if (
          this.showMyTicketModal == false &&
          this.$route.query.showModal == "true"
        ) {
          this.$router.push("/");
        }
      },
    },
  },
};
</script>

<style scoped>
.header {
  background: rgba(220, 220, 220, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  position: absolute;
  top: 0;
  z-index: 100;
}
.headerMenuItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #1b1b1b;
  margin-right: 32px;
  display: block;
  cursor: pointer;
}
.headerMenuInactiveItem:after {
  display: block;
  content: "";
  border-bottom: 1px solid #106634;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.headerMenuInactiveItem:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.headerMenuActiveItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #106634;
  border-bottom: 1px solid #106634;
}
.subitemsCard {
  width: 224px;
  height: 68px;
  border-radius: 10px;
  background: rgba(238, 238, 238, 0.8) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(2px) !important;
  padding: 10px;
}
.subitemsText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #1b1b1b !important;
  cursor: pointer;
  margin-bottom: 10px;
}
a {
  color: #1b1b1b !important;
  text-decoration: none;
}
.loginBtn {
  background: rgba(27, 27, 27, 0.9) !important;
  border-radius: 5px;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #eeeeee !important;
}
.loginBtnActive {
  color: #149e51 !important;
}
.loginBtnActive .profileIcon {
  background-color: #149e51 !important;
}
.loginBtn:hover {
  color: #149e51 !important;
}
.loginBtn:hover .profileIcon {
  background-color: #149e51 !important;
}
.profileIcon {
  width: 16.5px;
  height: 19.5px;
  margin-right: 14px;
  background-color: white;
  -webkit-mask: url("@/assets/img/iconsSvg/profileIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/profileIcon.svg") no-repeat center;
}
.profileDropdown {
  background: #1b1b1b !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 218px;
  height: 140px;
  padding: 12.5px 10px;
}
.profileUsernameText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #eeeeee;
}
.profileDropdownText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #eeeeee;
}
.profileDropdownText:hover {
  color: #149e51 !important;
}
.profileDropdownIconTicket {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: white;
  -webkit-mask: url("@/assets/img/iconsSvg/ticketIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/ticketIcon.svg") no-repeat center;
}
.profileDropdownIconSetting {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: white;
  -webkit-mask: url("@/assets/img/iconsSvg/settingIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/settingIcon.svg") no-repeat center;
}
.profileDropdownText:hover .profileDropdownIconTicket {
  background-color: #149e51 !important;
}
.profileDropdownText:hover .profileDropdownIconSetting {
  background-color: #149e51 !important;
}
</style>