import requestService from "../requestService";
import store from "@/store";
import router from "@/router";

export default {
	async signUp(form) {
		const response = await requestService.post(`/signup`, form)
		return response?.data
	},
	async signIn(form) {
		const response = await requestService.post(`/login`, form)
		return response?.data
	},
	async signInWithSocial(form) {
		const response = await requestService.post(`/auth/social`, form)
		return response?.data
	},
	async logout() {
		const response = await requestService.post(`/logout`, {}, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async refreshToken() {
		if (store?.getters?.loggedUser) {
			var form = new FormData();
			form.append("token", store?.getters?.loggedUser?.token);
			let res = requestService.post(`/refresh`, form).then((response) => {
				let result = response?.data.authorisation;
				store.dispatch('updateInfoLogged', {
					token: result.token
				})
				return response
			})
				.catch(() => {
					store.commit("clearUserLogged");
					router.push('/')
				})
			return res.data
		}
	},
	async resetPassword(form) {
		const response = await requestService.post(`/password/email`, form)
		return response?.data
	},
	async inputNewPassword(form) {
		const response = await requestService.post(`/password/reset`, form)
		return response?.data
	},
	async getUsers(page) {
		const response = await requestService.get(`/admin/users?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getUserById(id) {
		const response = await requestService.get(`/admin/users/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async createUser(form) {
		const response = await requestService.post(`/admin/user`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateUser(id, form) {
		const response = await requestService.post(`/admin/user/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async searchUser(search) {
		const response = await requestService.get(`/admin/users?search=${search}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
}