<template>
  <modalUI
    :visible="showProfileModal"
    modalTitle="Налаштування профілю"
    @close="$emit('close')"
    width="1200px"
  >
    <v-col cols="9" style="text-align: left">
      <p class="profileSectionName">Користувач</p>
      <v-row no-gutters align="center">
        <v-text-field
          outlined
          dense
          placeholder="Введіть тут.."
          label="Ім’я"
          class="textField"
          color="#C0C0C0"
          background-color="#FCFCFC"
          style="padding-right: 24px"
          height="50px"
          v-model="user.first_name"
          :success="!$v.user.first_name.$invalid"
          :error-messages="firstNameError"
        />
        <v-text-field
          outlined
          dense
          placeholder="Введіть тут.."
          label="Прізвище"
          class="textField"
          color="#C0C0C0"
          background-color="#FCFCFC"
          style="padding-right: 24px"
          height="50px"
          v-model="user.last_name"
          :success="!$v.user.last_name.$invalid"
          :error-messages="lastNameError"
        />
      </v-row>
      <v-row no-gutters align="center">
        <v-text-field
          outlined
          dense
          placeholder="Введіть тут.."
          label="Email"
          class="textField"
          color="#C0C0C0"
          background-color="#FCFCFC"
          style="padding-right: 24px"
          height="50px"
          v-model="user.email"
          :success="!$v.user.email.$invalid"
          :error-messages="emailError"
          autocomplete="off"
        />
        <v-text-field
          outlined
          dense
          v-mask="'+##(###) ###-##-##'"
          placeholder="Введіть тут.."
          label="Номер телефону"
          class="textField"
          color="#C0C0C0"
          background-color="#FCFCFC"
          style="padding-right: 24px"
          height="50px"
          v-model="user.phone_number"
          :success="!$v.user.phone_number.$invalid"
          :error-messages="phoneNumberError"
          autocomplete="off"
        />
      </v-row>
      <p class="profileSectionName">Пароль</p>
      <v-col cols="8" class="px-0 py-0">
        <v-row no-gutters align="start">
          <v-col cols="9" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              placeholder="Введіть тут.."
              label="Старий пароль"
              class="textField"
              color="#C0C0C0"
              background-color="#FCFCFC"
              style="padding-right: 24px"
              height="50px"
              :type="showPassword ? 'text' : 'password'"
              :error-messages="currentPasswordError"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="showPassword = !showPassword"
              v-model="password.current_password"
              :success="!$v.password.current_password.$invalid"
              ref="phoneField"
            />
          </v-col>
          <span
            class="changePasswordLabel"
            @click="showPasswordField = !showPasswordField"
            >Змінити пароль</span
          >
        </v-row>
        <v-expand-transition>
          <v-col cols="9" class="px-0 py-0" v-if="showPasswordField">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  placeholder="Введіть тут.."
                  label="Новий пароль"
                  class="textField"
                  color="#C0C0C0"
                  background-color="#FCFCFC"
                  style="padding-right: 24px"
                  height="50px"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="
                    showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="showNewPassword = !showNewPassword"
                  :success="!$v.password.password.$invalid"
                  :error-messages="passwordError"
                  v-model="password.password"
                />
              </template>
              <span
                >Пароль повинен містити мінімум 8 символів, одну велику та одну
                малу літеру, цифру, а токаж спеціальний символ</span
              >
            </v-tooltip>
            <v-text-field
              outlined
              dense
              placeholder="Введіть тут.."
              label="Підтвердіть пароль"
              class="textField"
              color="#C0C0C0"
              background-color="#FCFCFC"
              style="padding-right: 24px"
              height="50px"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="
                showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="showConfirmPassword = !showConfirmPassword"
              :success="!$v.password.confirm_password.$invalid"
              :error-messages="confirmPasswordError"
              v-model="password.confirm_password"
            />
          </v-col>
        </v-expand-transition>
      </v-col>
    </v-col>
    <v-row no-gutters justify="center">
      <v-col cols="4">
        <modal-btn
          width="380px"
          @click="updateUserData"
          :disabled="$v.user.$invalid"
          >Зберегти зміни</modal-btn
        >
        <modal-cancel-btn @click="$emit('close')">Скасувати</modal-cancel-btn>
      </v-col>
    </v-row>
  </modalUI>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";
import modalUI from "@/components/UI/modalUI.vue";
import ModalBtn from "@/components/UI/modalBtn.vue";
import ModalCancelBtn from "@/components/UI/modalCancelBtn.vue";
import { mapGetters } from "vuex";
import profileService from "@/requests/main/profileService";
export default {
  mixins: [validationMixin],
  components: { modalUI, ModalBtn, ModalCancelBtn },
  data: () => ({
    user: {},
    password: {},
    showPasswordField: false,
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  }),
  props: {
    showProfileModal: {
      require: true,
    },
  },
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(23),
      },
      email: {
        required,
        email,
      },
    },
    password: {
      current_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    this.getUserData();
    this.$refs.phoneField.$el
      .querySelector("input")
      .setAttribute("autocomplete", "off");
  },
  methods: {
    async getUserData() {
      await profileService.getUserProfile().then((res) => {
        if (res.status == "Success") {
          this.user = res.data;
        }
      });
    },
    async updateUserData() {
      this.$v.user.$touch();
      //this.showSuccessSnackbar = false;
      if (this.showPasswordField) {
        this.updateUserPassword();
      }
      if (!this.$v.user.$invalid) {
        let user = new FormData();
        user.append("first_name", this.user.first_name);
        user.append("last_name", this.user.last_name);
        user.append("phone_number", this.user.phone_number.replace(/\D+/g, ""));
        user.append("email", this.user.email);
        await profileService.updateUserProfile(user).then((res) => {
          if (res.status == "Success") {
            this.updateInfoLogged({
              email: this.user.email,
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              phone_number: this.user.phone_number.replace(/\D+/g, ""),
            });
            if (this.showPasswordField) {
              this.updateUserPassword();
            } else {
              this.showSuccessSnackbar = true;
            }
          }
        });
      }
    },
    async updateUserPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        let password = new FormData();
        password.append("current_password", this.password.current_password);
        password.append("password", this.password.password);
        password.append("password_confirmation", this.password.confirm_password);
        await profileService.updateUserPassword(password).then((res) => {
          if (res.status == "Success") {
            this.showSuccessSnackbar = true;
            this.isChangePassword = false;
            this.password = {};
            this.$v.$reset();
          } else if (res.message == "Wrong password") {
            this.serverPasswordError.push(
              "Існуючий пароль введено не правильно"
            );
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    firstNameError() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) {
        return errors;
      } else if (!this.$v.user.first_name.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) {
        return errors;
      } else if (!this.$v.user.last_name.required) {
        errors.push("");
      }
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.user.phone_number.$dirty) {
        return errors;
      } else if (!this.$v.user.phone_number.minLength) {
        errors.push("");
      } else if (!this.$v.user.phone_number.maxLength) {
        errors.push("");
      }
      !this.$v.user.phone_number.required && errors.push("");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      } else if (!this.$v.user.email.required) {
        errors.push("");
      }
      if (!this.$v.user.email.email) {
        errors.push("");
      }
      return errors;
    },
    currentPasswordError() {
      const errors = [];
      if (!this.$v.password.current_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.current_password.required) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.password.required) {
        errors.push("");
        return errors;
      }
      if (
        (!this.$v.password.password.valid &&
          this.$v.password.password.required) ||
        (!this.$v.password.password.minLength &&
          this.$v.password.password.required)
      ) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.confirm_password.sameAsPassword) {
        errors.push("");
        return errors;
      }
      !this.$v.password.confirm_password.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.textField {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656565;
  border-radius: 10px;
}
.profileSectionName {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1b1b1b;
}
.changePasswordLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #149e51;
  padding-top: 15px;
  cursor: pointer;
  user-select: none;
}
.changePasswordLabel:hover {
  text-decoration: underline;
}
.textField .v-text-field--outlined.v-input--dense .v-label {
  top: 0px;
}
</style>