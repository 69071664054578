<template>
  <v-dialog v-model="visibility" width="380px">
    <div class="modalBackground">
      <img src="@/assets/img/iconsSvg/successIcon.svg" alt="success icon" />
      <p style="margin-top: 24px" class="modalTitle">{{ title }}</p>
      <p class="modalText" v-if="text">
        Найближчим часом наш менеджер зв’яжиться з Вами для уточнення деталей
        повернення коштів
      </p>
      <modal-btn
        width="100%"
        @click="$emit('close'), $router.push('/')"
        v-if="searchBtn"
        style="margin-bottom: 12px"
        >Пошук маршруту</modal-btn
      >
      <modal-cancel-btn
        v-if="cancelBtn"
        @click="$emit('close')"
        text="Закрити"
      />
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import modalBtn from "../modalBtn.vue";
import ModalCancelBtn from "../modalCancelBtn.vue";
export default {
  components: { modalBtn, ModalCancelBtn },
  mixins: [modalMixin],
  props: {
    title: {
      require: true,
    },
    text: {
      require: false,
    },
    searchBtn: {
      require: false,
    },
    cancelBtn: {
      require: false,
    },
  },
};
</script>

<style scoped>
.modalBackground {
  background: #fcfcfc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: max-content;
  padding: 32px;
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #149e51;
}
.modalText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
</style>