import requestService from "../requestService";
import store from "@/store";

export default {
    async getOrders(status, page) {
        const response = await requestService.get(`/order/list/${status}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getOrderById(id) {
        const response = await requestService.get(`/order/${id}`)
        return response?.data
    },
    async getOrderByUuid(uuid) {
        const response = await requestService.get(`/order/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getOrderByQr(id) {
        const response = await requestService.get(`/order/scan/${id}`)
        return response?.data
    },
    async bookingTicket(form) {
        const response = await requestService.post(`/order`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async createOrder(form, order_uid) {
        const response = await requestService.post(`/order/${order_uid}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async updateOrder(form, order_uid) {
        const response = await requestService.post(`/order/${order_uid}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async changeOrderStatus(id, form) {
        const response = await requestService.post(`/admin/order/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async changeOrderStatusForUser(id, form) {
        const response = await requestService.post(`/order/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async sendOrderToEmail(uuid) {
        const response = await requestService.get(`/order/ticket/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessages() {
        const response = await requestService.get(`/admin/order_message`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessagesByStatus(status) {
        const response = await requestService.get(`/admin/order_message/list/${status}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessage(id) {
        const response = await requestService.get(`/admin/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async updateStatusOrderMessage(id, form) {
        const response = await requestService.post(`/admin/order_message/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async deleteOrderMessage(id) {
        const response = await requestService.get(`/admin/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async changeSeatsQuantity(id, form) {
        const response = await requestService.post(`/order/seats/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async cancelOrder(uuid) {
        const response = await requestService.post(`/order/cancel/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async payTicket(id) {
        const response = await requestService.post(`/order/pay/${id}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
}