<template>
  <modalUI
    v-if="showRegistrationModal"
    :visible="showRegistrationModal"
    modalTitle="Реєстрація"
    @close="$emit('close')"
  >
    <v-row no-gutters justify="center">
      <v-col class="px-0 py-0" cols="8" style="text-align: center">
        <p class="modalSubtitle">
          Створіть свій обліковий запис та отримайте доступ до усіх сервісів
          сайту
        </p>
        <v-row no-gutters justify="center" style="margin-top: 40px">
          <v-col cols="10" class="py-0 px-0" style="text-align: center">
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Прізвище"
              class="textField"
              height="50px"
              v-model="user.last_name"
              :success="!$v.user.last_name.$invalid"
              :error-messages="lastNameError"
            />
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Ім’я"
              class="textField"
              height="50px"
              v-model="user.first_name"
              :success="!$v.user.first_name.$invalid"
              :error-messages="firstNameError"
            />
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Email"
              class="textField"
              height="50px"
              v-model="user.email"
              :success="!$v.user.email.$invalid"
              :error-messages="emailError"
            />
            <v-tooltip bottom max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  color="#C0C0C0"
                  background-color="#FCFCFC"
                  placeholder="Введіть тут.."
                  label="Пароль"
                  class="textField"
                  height="50px"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="user.password"
                  :success="!$v.user.password.$invalid"
                  :error-messages="passwordError"
                  :append-icon="
                    showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="showPassword = !showPassword"
                />
              </template>
              <span
                >Пароль повинен містити мінімум 8 символів, одну велику та одну
                малу літеру, цифру, а токаж спеціальний символ</span
              >
            </v-tooltip>
            <v-text-field
              outlined
              dense
              color="#C0C0C0"
              background-color="#FCFCFC"
              placeholder="Введіть тут.."
              label="Повторіть пароль"
              class="textField"
              height="50px"
              v-model="user.password_confirm"
              :success="!$v.user.password_confirm.$invalid"
              :error-messages="passwordConfirmError"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="
                showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="showConfirmPassword = !showConfirmPassword"
            />
            <v-row no-gutters align="start">
              <input
                type="checkbox"
                style="margin-right: 8px; margin-top: 8px"
                v-model="user.agree_rules"
              />
              <v-col cols="10" class="px-0 py-0" style="text-align: left">
                <span class="optionText" style="color: #656565"
                  >Я ознайомився(-лась) і погоджуюсь з
                  <span style="color: #149e51" class="pointer"
                    >Правилами та Положеннями</span
                  >
                  даного сервісу</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters align="start" style="margin-top: 24px">
              <input
                type="checkbox"
                style="margin-right: 8px; margin-top: 6px;"
                v-model="user.agree_processing_personal_data"
              />
              <v-col class="px-0 py-0" cols="10" style="text-align: left">
                <span class="optionText" style="color: #656565"
                  >Я даю згоду на обробку моїх особистих даних</span
                >
              </v-col>
            </v-row>
            <modal-btn
              width="400px"
              :disabled="$v.$invalid"
              style="margin-top: 40px"
              @click="signUp"
              >Зареєструватися</modal-btn
            >
            <v-row
              no-gutters
              align="center"
              style="margin-top: 24px; margin-bottom: 20px"
            >
              <v-divider style="border: 0.5px solid rgba(27, 27, 27, 0.3)" />
              <span
                style="
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.1em;
                  color: #1b1b1b;
                  margin: 8px;
                "
                >або</span
              >
              <v-divider style="border: 0.5px solid rgba(27, 27, 27, 0.3)" />
            </v-row>
            <v-row no-gutters justify="center">
              <span class="goToSignIn pointer"
                >Уже зареєстровані?
                <span @click="$emit('goToSignIn')" class="signInLabel"
                  >Увійдіть!</span
                ></span
              >
            </v-row>
            <button
              @click="openGoogleSignInModal"
              data-onsuccess="onGoogleSignInSuccess"
              class="google-signin-button"
            >
              <img
                width="24px"
                height="24px"
                style="margin-right: 10px"
                src="@/assets/img/googleIcon.png"
                alt="google icon"
              />
              Увійти через Google
            </button>
            <fb-signin-button
              :params="fbSignInParams"
              @success="onSignInSuccess"
              @error="onSignInError"
            >
              <img
                width="24px"
                height="24px"
                style="margin-right: 10px"
                src="@/assets/img/facebookIcon.png"
                alt="google icon"
              />
              Увійти через Facebook
            </fb-signin-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </modalUI>
</template>

<script>
import modalUI from "@/components/UI/modalUI.vue";
import ModalBtn from "@/components/UI/modalBtn.vue";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authService from "@/requests/admin/authService";
import signinWithSocialsMixin from "@/mixins/signinWithSocialsMixin";
export default {
  mixins: [validationMixin, signinWithSocialsMixin],
  components: { modalUI, ModalBtn },
  data: () => ({
    user: {
      username: "",
      email: "",
      password: "",
      password_confirm: "",
    },
    showPassword: false,
    showConfirmPassword: false,
  }),
  props: {
    showRegistrationModal: {
      require: true,
    },
  },
  validations: {
    user: {
      last_name: {
        required,
      },
      first_name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs("password"),
      },
      agree_rules: {
        checked: (value) => value === true,
      },
      agree_processing_personal_data: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    ...mapActions(["updateInfo"]),
    async signUp() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let user = new FormData();
        user.append("first_name", this.user.first_name);
        user.append("last_name", this.user.last_name);
        user.append("email", this.user.email);
        user.append("password", this.user.password);
        user.append("password_confirmation", this.user.password_confirm);
        await authService
          .signUp(user)
          .then((data) => {
            this.snackbarText = "Користувач успішно створений";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
            console.log(data);
            this.updateInfo({
              token: data.token,
            });
            this.$emit("goToSignIn");
          })
          .catch((e) => {
            let response = e.response.data;
            if (response.status == "failed") {
              if (
                response.data.email[0] == "The email has already been taken."
              ) {
                this.emailErros.push("Користувач з таким email вже існує");
              }
            }
          });
      }
    },
  },
  computed: {
    lastNameError() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) {
        return errors;
      }
      !this.$v.user.last_name.required && errors.push("");
      return errors;
    },
    firstNameError() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) {
        return errors;
      }
      !this.$v.user.first_name.required && errors.push("");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push("");
      if (!this.$v.user.email.email) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (
        (!this.$v.user.password.valid && this.$v.user.password.required) ||
        (!this.$v.user.password.minLength && this.$v.user.password.required)
      ) {
        errors.push("");
        return errors;
      }
      !this.$v.user.password.required && errors.push("");
      return errors;
    },
    passwordConfirmError() {
      const errors = [];
      if (!this.$v.user.password_confirm.$dirty) {
        return errors;
      }
      if (!this.$v.user.password_confirm.sameAsPassword) {
        errors.push("");
        return errors;
      }
      !this.$v.user.password_confirm.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.goToSignIn {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #149e51;
}
.textField {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656565;
  border-radius: 10px !important;
}
.optionText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
}
.resetPasswordText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #149e51;
  cursor: pointer;
}
.resetPasswordText:hover {
  text-decoration-line: underline;
  color: #106634 !important;
}
input[type="checkbox"] {
  accent-color: #149e51;
}
.signInLabel:hover {
  color: #106634 !important;
}
</style>
<style>
.textField .v-icon {
  top: 5px;
}
</style>