<template>
  <v-footer
    height="max-content"
    style="background: #122021; border-radius: 2px; align-items: start"
  >
    <img
      src="@/assets/img/footerLine1.svg"
      style="position: absolute; object-fit: cover"
      width="100%"
      height="95%"
      alt="footer line"
    />
    <img
      src="@/assets/img/footerLine2.svg"
      style="position: absolute; bottom: 0 !important"
      width="100%"
      height="max-content"
      alt="footer line 2"
    />
    <v-col class="px-0 py-0" style="z-index: 10">
      <v-row no-gutters justify="center">
        <div
          style="margin-top: 5px; margin-left: 5px"
          :style="
            $vuetify.breakpoint.smAndDown ? 'width: 50px;' : 'width: 80px'
          "
        >
          <v-row no-gutters>
            <div class="backgroundCircle" v-for="i in 12" :key="i" />
          </v-row>
        </div>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="3"
          sm="12"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'text-align:center'
              : 'padding-left: 40px; margin-top: 50px'
          "
        >
          <img
            src="@/assets/img/siteLogo.png"
            width="180px"
            height="42px"
            alt="site_logo"
          />
          <v-row no-gutters style="margin-top: 30px">
            <a
              v-for="social in socialLinks"
              :key="social.id"
              :href="social.value"
              :style="social.id !== 4 ? 'margin-right: 32px' : ''"
            >
              <img
                :src="social?.image?.path"
                :alt="social.key"
                width="24px"
                height="24px"
              />
            </a>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="3"
          sm="12"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'text-align:left'
              : 'margin-left: 120px; margin-top: 50px'
          "
        >
          <p class="sectionName">Телефони диспетчерів</p>
          <a
            v-for="number in dispatcherPhoneNumbers"
            :key="number.id"
            :href="`tel: ${number.value}`"
          >
            <div class="sectionItem">
              {{ number.value }}
            </div>
          </a>
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="3"
          sm="12"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'text-align:left'
              : 'margin-left: 100px; margin-top: 50px'
          "
        >
          <p class="sectionName">Контактна інформація</p>
          <a :href="`mailto:${email.value}`">
            <div class="sectionItem">
              {{ email.value }}
            </div>
          </a>
          <div class="sectionItem">
            {{ address.value }}
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="3"
          sm="12"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'text-align:left'
              : 'margin-left: 100px; margin-top: 50px;'
          "
        >
          <p class="sectionName">Умови використання</p>
          <a v-for="term in termsOfUse" :key="term.id" :href="term.href">
            <div class="sectionItem">
              {{ term.name }}
            </div>
          </a>
          <v-row no-gutters justify="end" style="margin-top: 20px">
            <img
              src="@/assets/img/visa_mastercard_logo.png"
              style="object-fit: cover"
              width="100px"
              height="20px"
              alt="visa master card logo"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="copyrightText"
        style="margin-top: 64px !important; padding-bottom: 80px"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-col cols="12" style="text-align: center">
          <span
            style="
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              text-transform: uppercase;
              color: #eeeeee;
            "
          >
            © travel bus 2023
            <span v-if="new Date().getFullYear() > 2023"
              >- {{ new Date().getFullYear() }}</span
            ></span
          ></v-col
        >
        <v-col cols="12" style="margin: 12px 0px">
          <v-row no-gutters justify="center">
            <div
              style="
                border: 1px solid #2c3535;
                width: 15px;
                height: 1px;
                margin: 5px;
              "
            ></div>
          </v-row>
        </v-col>
        <v-col cols="12" style="text-align: center">
          <v-row no-gutters align="center" justify="center">
            <span
              style="
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #eeeeee;
                margin-left: 10px;
                margin-right: 8px;
              "
              >Developed by</span
            >
            <img
              src="@/assets/img/BMaxLogo.png"
              width="80px"
              height="40px"
              style="object-fit: cover; margin-left: 8px"
              alt="bmax logo"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-else
        no-gutters
        align="center"
        justify="center"
        style="margin-top: 107px"
      >
        <span
          style="
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
            color: #eeeeee;
          "
          >© travel bus 2018-{{ new Date().getFullYear() }}</span
        >
        <span
          style="
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #eeeeee;
            margin-left: 10px;
            margin-right: 8px;
          "
          >Developed by</span
        >
        <a href="https://bmax.com.ua/" target="_blank"
          ><img
            src="@/assets/img/BMaxLogo.png"
            width="80px"
            height="40px"
            alt="bmax_logo"
          />
        </a>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterComponent",
  data: () => ({
    socialLinks: [],
    termsOfUse: [
      {
        id: 1,
        href: "/transportation_rules",
        name: "Правила перевезень",
      },
      {
        id: 2,
        href: "/rules_and_general_terms",
        name: "Політика конфіденційності та загальні умови",
      },
      {
        id: 3,
        href: "/about_us",
        name: "Про нас",
      },
      {
        id: 4,
        href: "https://travelbus.biz.ua/paymentAndReturnConditions.pdf",
        name: "Оплата та умови повернення",
      },
      {
        id: 5,
        href: '/dogovir/publichnoho_dohovoru_oferty',
        name: 'Публічний договір оферта'
      }
    ],
    address: "",
    email: "",
    dispatcherPhoneNumbers: [],
  }),
  mounted() {
    setTimeout(() => {
      this.setLists();
    }, 1000);
  },
  methods: {
    setLists() {
      this.dispatcherPhoneNumbers = this.settingList.filter(
        (setting) => setting.type == "Dispatchers"
      );
      this.address = this.settingList.filter(
        (setting) => setting.key == "address"
      )[0];
      this.email = this.settingList.filter(
        (setting) => setting.key == "support_email"
      )[0];
      this.socialLinks = this.settingList.filter(
        (setting) => setting.type == "Socials"
      );
    },
  },
  computed: {
    ...mapGetters(["settingList"]),
  },
};
</script>

<style scoped>
.sectionName {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #eeeeee;
}
.sectionItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #b5b5b5;
  margin-bottom: 10px;
}
</style>