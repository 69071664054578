<template>
  <v-dialog v-model="visibility">
    <div style="background: #fcfcfc; padding: 16px;">
      <step-to-discount style="background: transparent;" />
      <modal-btn width="100%" @click="$emit('close')">Закрити</modal-btn>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import stepToDiscount from "./stepToDiscount.vue";
import ModalBtn from "@/components/UI/modalBtn.vue";
export default {
  components: { stepToDiscount, ModalBtn },
  mixins: [modalMixin],
};
</script>

<style>
</style>