<template>
  <span text class="cancelBtn" @click="$emit('click')">{{
    text ? text : "Скасувати"
  }}</span>
</template>

<script>
export default {
  props: {
    text: {
      require: false,
    },
  },
};
</script>

<style scoped>
.cancelBtn {
  text-align: center;
  color: #828282;
  cursor: pointer;
  text-transform: none;
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  user-select: none;
}
.cancelBtn:hover {
  color: #149e51;
  text-decoration: underline;
}
</style>