var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"380px"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('div',{staticClass:"modalBackground"},[_c('p',{staticClass:"modalTitle",staticStyle:{"margin-bottom":"24px"}},[_vm._v(" Ваш електронний квиток ")]),_c('p',{staticClass:"modalText"},[_vm._v("Відскануйте QR-код")]),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticStyle:{"width":"250px","height":"250px","margin-bottom":"24px"}},[_c('VueQRCodeComponent',{attrs:{"text":_vm.text,"size":250,"color":"#149E51","render-as":"svg"}})],1)]),_c('modal-cancel-btn',{staticStyle:{"margin-top":"0px"},attrs:{"text":"Скасувати"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"filename":`Квиток ${_vm.ticket?.info?.route_info?.departure_info.name} - ${
      _vm.ticket?.info?.route_info?.destination_info.name
    } ${new Date(_vm.ticket?.info?.departed_at.substr(0, 10)).toLocaleDateString(
      'uk-UA',
      { month: 'short', day: 'numeric' }
    )}  ${_vm.ticket?.ticket_info[_vm.indexTicket]?.first_name} 
    ${_vm.ticket?.ticket_info[_vm.indexTicket]?.last_name}`,"pdf-quality":2,"manual-pagination":true,"paginate-elements-by-height":200,"pdf-format":"a4","pdf-margin":100,"pdf-orientation":"portrait"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('pdf-ticket',{ref:"pdfTicket",attrs:{"id":"pdfTicket","ticket":_vm.ticket,"isPdf":_vm.isPdf,"text":_vm.qrText,"indexTicket":_vm.indexTicket}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }