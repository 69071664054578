<template>
  <modalUI
    :visible="showConfirmLogoutModal"
    color="Вихід"
    modalTitle="Вихід"
    @close="$emit('close')"
  >
    <v-row no-gutters justify="center">
      <v-col class="px-0 py-0" style="text-align: center" cols="8">
        <p class="confirmModalText">Ви підтверджуєте вихід?</p>
        <modal-btn width="420px" @click="logout" style="margin-bottom: 12px">
          Підтвердити
        </modal-btn>
        <modal-cancel-btn @click="$emit('close')" />
      </v-col>
    </v-row>
  </modalUI>
</template>

<script>
import modalUI from "@/components/UI/modalUI.vue";
import ModalBtn from "@/components/UI/modalBtn.vue";
import ModalCancelBtn from "@/components/UI/modalCancelBtn.vue";
import authService from "@/requests/admin/authService";
export default {
  components: { modalUI, ModalBtn, ModalCancelBtn },
  props: {
    showConfirmLogoutModal: {
      require: true,
    },
  },
  methods: {
    async logout() {
      this.$store.commit("clearUserLogged");
      await authService.logout().then((res) => {
        if (res.status == "Success") {
          this.$store.commit("clearUserLogged");
          sessionStorage.clear();
          this.$emit("close");
          this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style scoped>
.confirmModalText {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #1b1b1b;
}
</style>