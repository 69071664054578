<template>
  <div
    style="
      width: 100%;
      height: 50svh;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <v-progress-circular
      :size="size ? size : '180'"
      indeterminate
      color="#149E51"
    ></v-progress-circular>
  </div>
</template>
  
  <script>
export default {
  name: "LoaderComponent",
  props: {
    size: {
      require: false,
    },
  },
};
</script>
  
  <style>
</style>