<template>
  <div style="border-radius: 10px; text-align: left">
    <p class="bonusTitle">Бонус</p>
    <p class="bonusText" style="margin-bottom: 20px">
      Купуйте квитки, щоб отримати знижку
      <span style="color: #149e51">10%</span> на купівлю 6 квитка
    </p>
    <v-col
      cols="px-0 py-0"
      v-for="i in 6"
      :key="i"
      style="padding: 0px"
      :class="{
        activeDiscount: i > buyedTicket && i <= buyedTicket + bookingTicket,
        completeDicount: i <= buyedTicket,
      }"
    >
      <v-row no-gutters align="center">
        <div class="timelineCircle" style="margin-right: 8px">
          <div v-if="i == 6" class="presentIcon" />
          <img
            v-else-if="i <= buyedTicket"
            src="@/assets/img/iconsSvg/checkIcon.svg"
            width="46px"
            height="46px"
          />
          <div class="ticketIcon" v-else-if="i > buyedTicket" />
        </div>
        <v-col class="px-0 py-0">
          <span class="bonusTitle" style="font-size: 14px">{{ i }} квиток</span>
          <p class="bonusText" style="margin-bottom: 0px" v-if="i == 6">
            Отримайте знижку 10%
          </p>
        </v-col>
        <v-row no-gutters justify="end" v-if="i <= buyedTicket">
          <div
            style="
              border-radius: 10px;
              background: #149e51;
              color: #eee;
              font-family: 'MacPaw Fixel';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 4px 12px;
            "
          >
            Готово
          </div>
        </v-row>
        <v-row
          no-gutters
          justify="end"
          v-if="i > buyedTicket && i <= buyedTicket + bookingTicket"
        >
          <div
            style="
              border-radius: 10px;
              background: rgba(20, 158, 81, 0.1);
              color: #1b1b1b;
              font-family: 'MacPaw Fixel';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 4px 12px;
            "
          >
            В процесі
          </div>
        </v-row>
      </v-row>
      <div class="timelineLine" v-if="i !== 6"></div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/requests/main/profileService";
export default {
  data: () => ({
    buyedTicket: 0,
    bookingTicket: 0,
  }),
  mounted() {
    this.getUserProfile();
  },
  methods: {
    async getUserProfile() {
      await profileService.getUserProfile().then((res) => {
        this.buyedTicket = res.data.count_ticket;
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style scoped>
.bonusTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
}
.bonusText {
  color: #4f545a;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.timelineCircle {
  width: 48px;
  height: 48px;
  border: 2px solid #b5b5b5;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.ticketIcon {
  width: 24px;
  height: 24px;
  background-color: #b5b5b5;
  -webkit-mask: url("@/assets/img/iconsSvg/profileTicketIcon.svg") no-repeat
    center;
  mask: url("@/assets/img/iconsSvg/profileTicketIcon.svg") no-repeat center;
}
.presentIcon {
  width: 24px;
  height: 24px;
  background-color: #b5b5b5;
  -webkit-mask: url("@/assets/img/iconsSvg/presentIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/presentIcon.svg") no-repeat center;
}
.timelineLine {
  border-radius: 10px;
  background: #b5b5b5;
  width: 2px;
  height: 30px;
  margin: 4px 0px 4px 23px;
}
.activeDiscount .presentIcon {
  background-color: #149e51;
}
.activeDiscount .ticketIcon {
  background-color: #149e51;
}
.activeDiscount .timelineLine {
  background: #149e51;
}
.activeDiscount .timelineCircle {
  border-color: #149e51 !important;
  background-color: rgba(20, 158, 81, 0.05);
}
.completeDicount .timelineCircle {
  background: r#149e51;
  background-color: #149e51;
  border-color: #149e51;
}
.completeDicount .timelineLine {
  background-color: #149e51;
}
</style>