import cityService from "@/requests/admin/cityService"

export default {
    state: {
        citiesList: [],
        citiesListListTravelBus: [],
    },
    mutations: {
        setCitiesList(state, list) {
            state.citiesListTravelBus = list
        },
        clearCitiesList(state) {
            state.citiesListListTravelBus = []
        },
    },
    actions: {
        async getCitiesList({ commit, getters }) {
            try {
                await cityService.getAllCity().then((res) => {
                    if (res.status == 'Success') {
                        const newList = { ...getters.citiesListTravelBus, ...res.data }
                        commit('setCitiesList', newList)
                    }
                })
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        citiesListTravelBus: s => s.citiesListTravelBus,
    }
}
