import requestService from "../requestService";
import store from '@/store';

export default {
	async createService(form) {
		const response = await requestService.post(`/admin/service`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateService(id, form) {
		const response = await requestService.post(`/admin/service/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
    async getServices() {
		const response = await requestService.get(`/admin/service`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getService(id) {
		const response = await requestService.get(`/admin/service/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deleteService(id) {
		const response = await requestService.delete(`/admin/service/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
}