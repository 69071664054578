import requestService from "../requestService";
import store from '@/store';

export default {
	async createSetting(form) {
		const response = await requestService.post(`/admin/settings`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
    async updateSetting(id,form) {
		const response = await requestService.post(`/admin/settings/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
    async getSettingList() {
		const response = await requestService.get(`/settings`)
		return response?.data
	},
    async getSettingListForAdmin() {
		const response = await requestService.get(`/admin/settings`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
    async getSetting(id) {
		const response = await requestService.post(`/admin/setting/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deleteSetting(id) {
		const response = await requestService.delete(`/admin/settings/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	}
}