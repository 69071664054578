import busServicesService from "@/requests/admin/busServicesService"

export default {
    state: {
        busServicesListTravelBus: []
    },
    mutations: {
        setServicesList(state, list) {
            state.busServicesListTravelBus = list
        },
        clearServicesList(state) {
            state.busServicesListTravelBus = []
        },
    },
    actions: {
        async getServicesList({ commit, getters }) {
            try {
                await busServicesService.getServices().then((res) => {
                    if (res.status == 'Success') {
                        const newList = { ...getters.busServicesListTravelBus, ...res.data }
                        commit('setServicesList', newList)
                    }
                })
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        busServicesListTravelBus: s => s.busServicesListTravelBus,
    }
}
