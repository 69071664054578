<template>
  <v-dialog
    v-model="visibility"
    :width="width ? width : '780px'"
    height="max-content"
    style="border-radius: 20px; overflow-x: hidden"
  >
    <div
      class="modalBackground"
      :style="width ? `width:${width}` : 'width: 780px;'"
    >
      <v-row no-gutters justify="end">
        <div
          alt="close_icon"
          style="position: absolute"
          class="pointer closeIcon"
          @click="$emit('close')"
        />
      </v-row>
      <p
        class="modalTitle"
        :style="color ? `color: ${color}` : 'color: #149e51;'"
      >
        {{ modalTitle }}
      </p>
      <slot></slot>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  props: {
    modalTitle: {
      require: true,
    },
    width: {
      require: false,
    },
    color: {
      require: false,
    },
  },
};
</script>

<style scoped>
.modalBackground {
  height: max-content;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  padding: 32px;
}
.modalTitle {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #149e51;
}
.closeIcon {
  width: 32px;
  height: 32px;
  background-color: #b9b9b9;
  -webkit-mask: url("@/assets/img/iconsSvg/closeIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/closeIcon.svg") no-repeat center;
}
.closeIcon:hover {
  background: #149e51;
}
</style>