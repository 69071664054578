<template>
  <v-dialog
    v-model="visibility"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '700px'"
    height="max-content"
  >
    <div class="modalBackground">
      <v-row no-gutters justify="center">
        <img
          src="@/assets/img/siteLogo.png"
          width="120px"
          height="28px"
          style="object-fit: cover"
        />
      </v-row>
      <img
        src="@/assets/img/scammersBackground.png"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        :height="$vuetify.breakpoint.smAndDown ? '' : '455px'"
        style="object-fit: cover; position: absolute; margin-top: 42px"
      />
      <div
        style="height: max-content"
        :style="
          !$vuetify.breakpoint.smAndDown
            ? 'width: 636px;margin-top: 32px;'
            : 'width: 100%;margin-top: 20px;'
        "
      >
        <h2 class="headText">Увага шахраї</h2>
        <div class="contentText">
          <p>
            Протягом останніх днів отримуємо звернення на рахунок шахрайської
            діяльності під назвою нашої компанії.
          </p>
          <p>
            Під псевдонімом компанії TravelBus діє: Олександр Гладун ( скритий
            номер телефону, вказано тільки псевдонім
            <span style="color: #ca0000">@TrevelBus</span> ), Назар (
            <span style="color: #ca0000">@Travebou</span> ), а також Мазур Юрій.
            Найчастіше шахраї використовують платформу Telegram, Viber.
          </p>
          <p>
            Наголошуємо, що компанія TravelBus надає послуги виключно державною
            мовою.
          </p>
          <p style="color: #149e51; font-weight: 700">
            Офіційні контакти компанії:
          </p>
          <p>+38 (099) 522-60-00</p>
          <p>+38 (097) 532-60-00</p>
          <p>+38 (099) 025-27-18</p>
          <p>
            Просимо вас бути обережними та застерігаємо вас не стати жертвою
            шахрайства.
          </p>
        </div>
        <v-row no-gutters justify="center">
          <modal-cancel-btn
            text="Закрити"
            style="color: white"
            @click="$emit('close')"
          />
        </v-row>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import ModalCancelBtn from "../modalCancelBtn.vue";
export default {
  mixins: [modalMixin],
  components: {
    ModalCancelBtn,
  },
};
</script>

<style
    scoped>
@media screen and (max-width: 1023px) {
  .modalBackground {
    padding: 20px;
    background: linear-gradient(
      117deg,
      #ab2a44 0.25%,
      #0a193a 52.88%,
      #374b78 115.67%
    );
  }
  .headText {
    color: #eee;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .contentText {
    margin-top: 20px;
    color: #eee;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  p {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 1024px) {
  .modalBackground {
    padding: 32px;
    background: linear-gradient(
      117deg,
      #ab2a44 0.25%,
      #0a193a 52.88%,
      #374b78 115.67%
    );
  }
  .headText {
    color: #eee;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .contentText {
    margin-top: 20px;
    color: #eee;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
</style>
<style>
.v-dialog {
  border-radius: 20px !important;
}
</style>