<template>
  <div class="bottomMenuBackground">
    <v-row no-gutters justify="center">
      <div
        class="bottomMenuItem"
        :class="$route.path == item.path ? 'activeBottomMenuItem' : ''"
        style="text-align: center"
        v-for="(item, index) in menuItems"
        :key="item.id"
        :style="index == 4 ? 'margin-right: 0px' : 'margin-right: auto'"
      >
        <router-link :to="item.path" style="text-align: center">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 30px;
            "
          >
            <div :class="item.icon" />
          </div>
          <p class="bottomMenuItemText">{{ item.title }}</p>
        </router-link>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuItems: [
      {
        id: 1,
        icon: "homeIcon",
        title: "Головна",
        path: "/",
      },
      {
        id: 2,
        icon: "routeIcon",
        title: "Маршрути",
        path: "/routes",
      },
      {
        id: 3,
        icon: "ticketIcon",
        title: "Мої квитки",
        path: "/tickets",
      },
      {
        id: 4,
        icon: "contactIcon",
        title: "Контакти",
        path: "/contact",
      },
      {
        id: 5,
        icon: "accountIcon",
        title: "Ще",
        path: "/other",
      },
    ],
  }),
};
</script>

<style scoped>
.bottomMenuBackground {
  width: 100%;
  height: 72px;
  padding: 4px 20px;
  position: fixed;
  z-index: 100;
  bottom: 0px;
  background: #fcfcfc;
  border-top: 1px solid #bcbcbc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomMenuItem {
  height: 50px;
  text-align: center;
}
.bottomMenuItemText {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 0px;
  color: #1b1b1b;
}
.activeBottomMenuItem .homeIcon {
    background-color: #149E51;
}
.activeBottomMenuItem .routeIcon {
    background-color: #149E51;
}
.activeBottomMenuItem .ticketIcon {
    background-color: #149E51;
}
.activeBottomMenuItem .contactIcon {
    background-color: #149E51;
}
.activeBottomMenuItem .accountIcon {
    background-color: #149E51;
}
.activeBottomMenuItem .bottomMenuItemText {
    color: #149E51;
}
.homeIcon {
  width: 28px;
  height: 28px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/mobileIcons/homeIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcons/homeIcon.svg") no-repeat center;
}
.routeIcon {
  width: 35px;
  height: 29px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/mobileIcons/routeIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcons/routeIcon.svg") no-repeat center;
}
.ticketIcon {
  width: 32px;
  height: 32px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/mobileIcons/ticketIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcons/ticketIcon.svg") no-repeat center;
}
.contactIcon {
  width: 28px;
  height: 28px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/mobileIcons/contactIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcons/contactIcon.svg") no-repeat center;
}
.accountIcon {
  width: 35px;
  height: 32px;
  background-color: #1b1b1b;
  -webkit-mask: url("@/assets/img/mobileIcons/accountIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcons/accountIcon.svg") no-repeat center;
}
</style>