<template>
  <v-btn
    class="modalBtn"
    :width="width ? width : ''"
    height="50px"
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click')"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: {
      require: false,
    },
    width: {
      require: true,
    },
    loading: {
      require: false,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1023px) {
  .modalBtn {
    background: #149e51 !important;
    border-radius: 10px;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #eeeeee !important;
    text-transform: none;
    margin-top: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .modalBtn {
    background: #149e51 !important;
    border-radius: 10px;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: #eeeeee !important;
    text-transform: none;
    margin-top: 24px;
  }
}
.modalBtn:hover {
  background: #106634 !important;
}
.modalBtn.theme--light.v-btn.v-btn--disabled {
  color: #eeeeee !important;
  background-color: #989898 !important;
}
</style>